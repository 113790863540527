import React, { useState, useEffect } from 'react';
import Button from '@ux/button';
import ProgressBar from '@ux/progress-bar';
import Modal from '@ux/modal';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

const LoadingModal = ({
  intl,
  onClose,
  maxSeconds,
  stopPercentage,
  productName,
  error,
}) => {
  const [progress, setProgress] = useState(0);
  const [startTime] = useState(() => new Date());

  const max = stopPercentage / 100;
  const easeOutCubic = (t) => --t * t * t + 1; // https://gist.github.com/gre/1650294

  const update = () => {
    requestAnimationFrame(() => {
      const newProgress = Math.min(
        (new Date() - startTime) / (maxSeconds * 1000),
        max,
      );
      setProgress(easeOutCubic(newProgress));

      if (easeOutCubic(newProgress) < max) {
        update();
      }
    });
  };

  useEffect(() => {
    update();
  }, [startTime]);

  return (
    <Modal
      id="loading-modal"
      title={intl.formatMessage({ id: 'common__loading' })}
    >
      <div className="loading-modal-container">
        {!error && (
          <div className="mb-3">
            <ProgressBar
              label={
                <FormattedMessage
                  id="loadingmodal_please_wait"
                  values={{ productName }}
                />
              }
              showPercent
              value={progress}
              max={1}
              min={0}
            />
          </div>
        )}

        {error && (
          <div className="mb-3">
            <p className="text-danger">
              <strong>{error}</strong>
            </p>
            <p>
              <Button
                design="primary"
                onClick={onClose}
                text={intl.formatMessage({ id: 'common__ok' })}
              />
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

LoadingModal.propTypes = {
  intl: PropTypes.shape(IntlShape),
  onClose: PropTypes.func,
  maxSeconds: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  stopPercentage: PropTypes.number,
  error: PropTypes.node,
};

LoadingModal.defaultProps = {
  stopPercentage: 99,
};

export { LoadingModal };

export default injectIntl(LoadingModal);
