import { Endpoints, ActionList } from '../api-data';
import { useData } from './util.js';

const useActiveSupportTickets = (initalSupportTickets = [], options = {}) => {
  return useData({
    endpoint: Endpoints.ActiveSupportTicket,
    action: ActionList,
    initialValue: initalSupportTickets,
    outputFilter: (o) => o,
    skip: options.skip ?? false,
  });
};

export { useActiveSupportTickets };
