import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

export class WSSSetupSuccessResultRowBase extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="wss-setup-result-row">
        <div className="wss-setup-result-icon">{this.props.icon}</div>
        <div className="wss-setup-result-text">
          <div className="wss-setup-result-text-description">
            <span className={this.props.textColorClass}>
              {this.props.header}{' '}
            </span>
            <span className={this.props.descriptionColorClass}>
              ({this.props.warningMessage}
              {!this.props.warningMessage && this.props.description})
            </span>
          </div>
        </div>
        <div className="float-clear"></div>
      </div>
    );
  }
}

WSSSetupSuccessResultRowBase.propTypes = {
  icon: PropTypes.node,
  header: PropTypes.object,
  description: PropTypes.object,
  descriptionColorClass: PropTypes.string,
  textColorClass: PropTypes.string,
  warningMessage: PropTypes.string,
};

WSSSetupSuccessResultRowBase.defaultProps = {
  warningMessage: '',
};

export default injectIntl(WSSSetupSuccessResultRowBase);
