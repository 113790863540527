import { useState, useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Alert from '@ux/alert';
import Button from '@ux/button';
import Text from '@ux/text';
import PropTypes from 'prop-types';
import Search from '@ux/search';
import Spinner from '@ux/spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import { Recommendations } from '@px/recommendations';
import Modal from '@ux/modal';

import { subscriptionType, ticketType } from '@/common/types';
import { isMSSL, isMsslOnetimeService } from '@/helpers/ApiHelper';
import { isEmpty } from '@/common/helpers';
import { MonitorsSiteSyncStatus, MonitorsVersion } from '@/common/enums';
import ExperimentWrapper from '@/components/common/experiment-wrapper';

import MySitesTitle from './title';
import MsslError from './mssl-error-modal';
import SiteCard from '../site-card';
import { useUnifiedSites } from './unify';
import { useInactivePlans } from './inactive-plans';
import LoadingMessage from './loading-message';
import Errors from './errors';
import Sort from '../sort';
import Track, { events } from '../track';
import BuyAPlanButton from '../buy-a-plan-button';
import { useBackupServer } from '../services/backups';
import { useMonitoringServer } from '../services/monitoring';
import { useSubscriptions } from '../services/subscriptions';
import {
  useFlag,
  SECUI_SUPPORT_MGMT_1,
  SECUI_MSSL_ONETIME,
  SECUI_GLOBAL_SUPPORT_BUTTON,
  SECUI_SUPPORT_V2,
} from '../flags';
import { useMSSLTickets } from '../services/mssl';
import { useActiveSupportTickets } from '../services/support';
import SupportButton from '../support-button/index';
import CreateTicket from '../support/create-ticket';

const DynamicNav = dynamic(() => import('../navigation'), { ssr: false });
const DynamicNoPlans = dynamic(() => import('../no-plan'), { ssr: false });
const DynamicNoSites = dynamic(() => import('../no-sites'), { ssr: false });
const DynamicInactivePlans = dynamic(() => import('../inactive-plans'), {
  ssr: false,
});
const DynamicSetupController = dynamic(() => import('../setup-controller'), {
  ssr: false,
});
const DynamicRekeyController = dynamic(() => import('../rekey-controller'), {
  ssr: false,
});
const DynamicUccWildcardSites = dynamic(() => import('../ucc-wc-sites'), {
  ssr: false,
});
const DynamicMsslOnetimeSites = dynamic(
  () => import('../../components/mssl/mssl-onetime-sites'),
  {
    ssr: false,
  },
);
const DynamicMsslShortForm = dynamic(() => import('./mssl-short-form'), {
  ssr: false,
});
const DynamicMultiSitePlans = dynamic(() => import('../multi-site-plans'), {
  ssr: false,
});

const navItems = [
  {
    translationId: 'mysites__title',
    to: '/',
  },
  {
    translationId: 'support',
    to: '/support',
  },
];

export const MySites = (props) => {
  const intl = useIntl();
  const { initialSubscriptions, initialMsslTickets } = props;

  const supportMgmtFlag = useFlag(SECUI_SUPPORT_MGMT_1);
  const supportV2Flag = useFlag(SECUI_SUPPORT_V2);
  const GlobalSupportBtnFlag =
    useFlag(SECUI_GLOBAL_SUPPORT_BUTTON) && !supportV2Flag;

  const subscriptions = useSubscriptions(initialSubscriptions);
  const msslTickets = useMSSLTickets(initialMsslTickets);
  const hostingProducts = subscriptions.data.withHostingProducts();
  const msslProducts = subscriptions.data.withManagedSSL();
  const activeSupportTickets = useActiveSupportTickets([], {
    skip: !supportV2Flag,
  });

  const sites = useUnifiedSites({
    subscriptions,
    msslTickets,
    supportTickets: activeSupportTickets,
  });

  const inactive = useInactivePlans({ subscriptions, msslTickets });

  const domains = useMemo(() => sites.map((s) => s.domain), [sites]);

  const { data: backupsServer } = useBackupServer();
  const { data: monitoringServer } = useMonitoringServer();
  const [search, setSearch] = useState('');
  const [sorter, setSort] = useState(() => (i) => i);
  const [selectedMsslSub, setSelectedMsslSub] = useState(null);
  const [showCreateTicketTakeOver, setShowCreateTicketTakeOver] =
    useState(false);
  const [recommendationsOpen, openRecommendations] = useState(false);

  const onetimeFF = useFlag(SECUI_MSSL_ONETIME);
  const router = useRouter();
  const setup = router.query && router.query.setup ? router.query.setup : null;
  const rework =
    router.query && router.query.rework ? router.query.rework : null;
  const site = router.query && router.query.site ? router.query.site : null;

  const isSubscriptionInUccSites = (uccSites, subscriptionId) => {
    return uccSites.find((site) => site.subscription_id === subscriptionId);
  };

  const isSubscriptionInOnetimeSites = (onetimeSites, subscriptionId) => {
    return onetimeSites.find((site) => site.subscription_id === subscriptionId);
  };
  const useUccWildcardSites = (allSites) => {
    const uccWildcardSites = [];
    allSites.forEach((site) => {
      if (typeof site.mssl !== 'undefined') {
        site.mssl.tickets.map((ticket) => {
          if (
            ticket.rekey_allowed &&
            ticket.id === ticket.primary_ticket_id &&
            (ticket.ssl_type === 'UCC' || ticket.ssl_type === 'Wildcard') &&
            !isSubscriptionInUccSites(uccWildcardSites, ticket.subscription_id)
          ) {
            const ticketSubscription = site.subscriptions.find((sub) => {
              return (
                sub?.subscriptionId === ticket.subscription_id &&
                sub?.status === 'ACTIVE'
              );
            });
            if (ticketSubscription) {
              ticket.pfid = ticketSubscription.product.pfid;
              ticket.label = ticketSubscription.product.label;
              uccWildcardSites.push(ticket);
            }
          }
        });
      }
    });
    return uccWildcardSites;
  };

  // to determine if there are MSSL one-time service subscriptions with service slots available
  const useOnetimeSites = (allSites) => {
    const onetimeSites = [];
    allSites.forEach((site) => {
      if (typeof site.mssl !== 'undefined') {
        site.mssl.tickets.map((ticket) => {
          if (
            ticket.one_time_service &&
            ticket.remaining_slots > 0 &&
            !isSubscriptionInOnetimeSites(onetimeSites, ticket.subscription_id)
          ) {
            const ticketSubscription = site.subscriptions.find((sub) => {
              return (
                sub &&
                sub.subscriptionId === ticket.subscription_id &&
                sub.status === 'ACTIVE'
              );
            });
            if (ticketSubscription) {
              // keep unlimited one-time service subscriptions with inactive plans
              ticket.pfid = ticketSubscription.product.pfid;
              ticket.label = ticketSubscription.product.label;
              onetimeSites.push(ticket);
            }
          }
        });
      }
    });
    return onetimeSites;
  };

  const sumOfInactive = () => {
    let counter = Object.keys(inactive.websiteSecurity).length;
    counter += Object.keys(inactive.backups).length;
    counter += Object.keys(inactive.ssl).length;

    return counter;
  };

  const takeMsslSub = () => {
    let prod = null;

    for (const [key, value] of Object.entries(inactive.ssl)) {
      // the mssl short form should not try to utilize MSSL One-time Service subscriptions
      if (isMSSL(Number(key)) && !isMsslOnetimeService(Number(key))) {
        prod = value[0];
        delete inactive.ssl[key];
        return prod;
      }
    }

    return prod;
  };

  useEffect(() => {
    setSelectedMsslSub(takeMsslSub());
  }, []);

  useEffect(() => {
    if (site) {
      setSearch(site);
    }
  }, [site]);

  const subscriptionsLoading =
    isEmpty(initialSubscriptions) && subscriptions.loading;

  const anyWssIsLoading = Object.values(sites.loading).some(Boolean);
  const hasSites = sites.length > 0;
  const hasInactivePlans = sumOfInactive() > 0;
  const uccWildcardSites = useUccWildcardSites(sites);
  const hasUCCWildcardSites = uccWildcardSites.length > 0;
  const onetimeSites = useOnetimeSites(sites);
  const hasOnetimeSites = onetimeSites.length > 0;
  const multiSiteSubscriptions = subscriptions.data.withWebSecurityMultiSite();
  const hasUnsyncedMonitors = sites.some((site) => {
    return (
      site.monitoring.version === MonitorsVersion.TWO &&
      site.monitoring?.synced === MonitorsSiteSyncStatus.NOT_SYNCED
    );
  });

  return (
    <div data-testid="my-sites">
      <CreateTicket
        show={showCreateTicketTakeOver}
        onClose={() => setShowCreateTicketTakeOver(false)}
        domains={domains}
      />

      {supportMgmtFlag && <DynamicNav items={navItems} />}

      {setup && (
        <DynamicSetupController
          subscriptions={subscriptions}
          msslTickets={msslTickets}
        />
      )}

      {subscriptionsLoading && (
        <div className="p-5 ms5 text-center text-muted">
          <FormattedMessage id={'common__loading'} />
          &hellip;
        </div>
      )}

      {subscriptions.error && (
        <div className="col-xs-12 notification-row">
          <Alert
            id="mysites-error"
            title={intl.formatMessage({ id: 'mysites__error_subscriptions' })}
            emphasis="critical"
          />
        </div>
      )}

      {hasUnsyncedMonitors && hasSites && (
        <div className="col-xs-12 notification-row px-0 mb-3">
          <Alert
            id="mysites-error"
            title={intl.formatMessage({
              id: 'mysites__error_missing_monitors_sites',
            })}
            emphasis="critical"
          />
        </div>
      )}

      {!subscriptionsLoading && !subscriptions.error && (
        <>
          <div className="row">
            {hasSites && (
              <div className="col-xs-12 notification-row">
                <LoadingMessage {...sites.loading} />
                <Errors errors={sites.error} />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-12 my-4 d-flex flex-column flex-md-row justify-content-between align-items-md-center">
              <MySitesTitle sites={sites} />
              <div className="d-flex flex-column flex-md-row pt-2 px-0 mt-2 mt-md-0 justify-content-between justify-content-md-end">
                <div className="d-flex flex-column flex-sm-row justify-content-between justify-content-md-end mr-md-3 mb-3 mb-md-0">
                  {supportV2Flag && (
                    <Button
                      design="secondary"
                      text={intl.formatMessage({
                        id: 'support__create_support_ticket',
                      })}
                      onClick={() => setShowCreateTicketTakeOver(true)}
                    />
                  )}
                  <ExperimentWrapper
                    experimentId="secui_ab_my_sites_buyplan_wss"
                    cohortsViews={{
                      ineligible: (
                        <div data-exp-cohortId="ineligible">
                          <BuyAPlanButton
                            eventId={events.SECUI_MYSITES_BUY_NOW_BTN_CLICK}
                          />
                        </div>
                      ),
                      on: (
                        <div data-exp-cohortId="on">
                          <Button
                            role="link"
                            id="buy-a-plan-btn-recommendation"
                            design="primary"
                            className="ml-md-3 d-flex d-sm-inline-block flex-grow-1 flex-grow-sm-0 text-center"
                            text={intl.formatMessage({
                              id: 'noplans__button',
                            })}
                            onClick={() => openRecommendations(true)}
                          />
                        </div>
                      ),
                      off: (
                        <div data-exp-cohortId="off">
                          <BuyAPlanButton
                            eventId={events.SECUI_MYSITES_BUY_NOW_BTN_CLICK}
                          />
                        </div>
                      ),
                    }}
                  />

                  {GlobalSupportBtnFlag && (
                    <div className="flex ml-md-3 my-3 my-sm-0">
                      <SupportButton />
                    </div>
                  )}
                </div>
                <div data-testid="sec-dash-sort-dropdown">
                  <Sort onChange={(sortFn) => setSort(() => sortFn)} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-4 mt-3 search-wrapper">
              <Track
                id={events.MYSITES_SEARCH_FOCUS}
                desc="focus"
                events="onFocus"
              >
                <Search
                  id="dashboard-search"
                  data-testid={'sec-dash-search-input'}
                  placeholder={intl.formatMessage({
                    id: 'mysites__search_your_sites_here',
                  })}
                  onChange={(value) => {
                    setSearch(value.toLowerCase());
                  }}
                  value={search}
                />
              </Track>
            </div>
          </div>
          <div className="row d-flex flex-column mt-3">
            {search === '' && (
              <>
                {selectedMsslSub && (
                  <DynamicMsslShortForm
                    hostingProducts={hostingProducts}
                    msslSub={selectedMsslSub}
                  />
                )}
                {hasUCCWildcardSites && rework && (
                  <DynamicRekeyController
                    subscriptions={subscriptions}
                    msslTickets={msslTickets}
                  />
                )}
                {!hasUCCWildcardSites && rework && <MsslError intl={intl} />}
                {hasUCCWildcardSites && (
                  <DynamicUccWildcardSites sites={uccWildcardSites} />
                )}
                {onetimeFF && hasOnetimeSites && (
                  <DynamicMsslOnetimeSites sites={onetimeSites} />
                )}
                {multiSiteSubscriptions.length > 0 && (
                  <DynamicMultiSitePlans
                    subscriptions={multiSiteSubscriptions}
                  />
                )}
                {inactive && (
                  <DynamicInactivePlans
                    {...inactive}
                    uccWildcardSites={uccWildcardSites}
                  />
                )}
              </>
            )}

            {sorter(sites).map((domain) => {
              if (!domain.domain.toLowerCase().includes(search)) {
                return null;
              }

              let sslOrMsslLoading = true;
              if (!sites.loading.subscriptions && domain.ssl.length >= 1) {
                sslOrMsslLoading = false;
              } else {
                sslOrMsslLoading = sites.loading.mssl;
              }

              return (
                <SiteCard
                  key={domain.domain}
                  siteSubscriptions={domain.subscriptions}
                  domainData={domain}
                  hostingProducts={hostingProducts}
                  msslProducts={msslProducts}
                  subscriptions={subscriptions}
                  rating={domain.letterScore}
                  monitoring={{
                    loading: sites.loading.monitoring,
                    monitoring: domain.monitoring,
                    server: monitoringServer,
                  }}
                  support={domain.support}
                  firewall={{
                    loading: sites.loading.firewall,
                    firewall: domain.firewall,
                  }}
                  ssl={{
                    loading: sslOrMsslLoading,
                    mssl: domain.mssl,
                    ssl: domain.ssl,
                  }}
                  backups={{
                    loading: sites.loading.backups,
                    backups: domain.backups,
                    server: backupsServer,
                  }}
                  error={sites.error}
                />
              );
            })}
          </div>

          {!subscriptionsLoading &&
            !subscriptions.error &&
            !anyWssIsLoading &&
            !hasSites &&
            !hasInactivePlans && <DynamicNoPlans />}

          {!subscriptionsLoading &&
            !anyWssIsLoading &&
            !hasSites &&
            hasInactivePlans && <DynamicNoSites {...inactive} />}

          {sites.loading.any && (
            <div className="p-5 text-center">
              <Spinner size="lg" />
            </div>
          )}

          {recommendationsOpen && (
            <Modal onClose={() => openRecommendations(false)} focusLock={false}>
              <div className="text-center">
                <Text.h2
                  text={intl.formatMessage({
                    id: 'mysites__inapp_select_plan_title',
                  })}
                  as="heading"
                />
                <Text.h4
                  text={intl.formatMessage({
                    id: 'mysites__inapp_select_plan_desc',
                  })}
                  as="title"
                />
              </div>
              <Recommendations
                id="wss-recommendation"
                component="nextSteps"
                placementKey="webSecurityPlans"
                appKey="web-security"
                isInModal={true}
                openInModal={false}
                onClose={() => openRecommendations(false)}
                onPurchaseComplete={() => {
                  openRecommendations(false);
                  window.location.reload();
                }}
              />
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

MySites.propTypes = {
  initialSubscriptions: PropTypes.arrayOf(subscriptionType),
  initialMsslTickets: PropTypes.arrayOf(ticketType),
};

export default MySites;
