import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ScoreContext = React.createContext();

const colorClass = (type = 'stroke', score) => {
  // red
  if (score <= 40) {
    return `svg-${type}-danger`;
  }

  // orange
  if (score > 40 && score < 81) {
    return `svg-${type}-warning`;
  }

  // green
  if (score >= 81) {
    return `svg-${type}-success`;
  }
};

const useTween = (end) => {
  const [start, setStart] = useState(0);
  const [animated, setAnimated] = useState(0);

  // cubic ease in/out
  // https://gist.github.com/gre/1650294
  const tween = (t) => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };

  useLayoutEffect(() => {
    let canceled = false;
    if (canceled) {
      return;
    }

    setStart(animated);

    return () => {
      canceled = true;
    };
  }, [end]);

  useLayoutEffect(() => {
    // move 1.66% of the full arc each frame. At 60fps, the arc will go from
    // 0 to 100 in one second. Shorter transitions move at the same speed and
    // thus take less time.
    const speed = 1.66;
    let requestId;
    const step = (progress) => {
      if (progress > 100 || progress < 0) {
        return;
      }
      requestId = requestAnimationFrame(() => {
        if (animated < end) {
          const tweened = tween(progress / 100) * (end - start) + start;
          setAnimated(Math.min(tweened.toFixed(3), end));

          return step(progress + speed);
        }

        if (animated > end) {
          const tweened = start - tween(progress / 100) * (start - end);
          setAnimated(Math.max(tweened.toFixed(3), end));

          return step(progress + speed);
        }
      });
    };
    step(0);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [start, end]);

  return animated;
};

const SecurityGradeArc = ({ children, size }) => {
  // There are currently some scaling bugs when using any size except for this exact one
  size = size || 220;
  const score = useTween(React.useContext(ScoreContext));

  const radius = size / 2;
  const circumference = Math.PI * radius * 2; // 2πr
  const scoreArcPercent = (score / 100) * (215 / 360);

  var scoreArc = null;
  if (score > 0) {
    const tipRadians = (score / 100) * (0.667 * Math.PI * 2);
    scoreArc = (
      <g style={{ transformOrigin: '50%', transform: 'rotate(150deg)' }}>
        <circle
          r={radius - 12}
          cx={radius}
          cy={radius}
          className={colorClass('stroke', score)}
          strokeWidth="24"
          strokeDasharray={`${circumference * scoreArcPercent}, ${
            circumference * (1 - scoreArcPercent)
          }`}
          strokeLinecap="round"
          style={{ transition: 'stroke 0.2s ease-in-out' }}
          fill="none"
        />
        <circle
          r="14"
          cy={radius + (radius - 12) * Math.sin(tipRadians)}
          cx={radius + (radius - 12) * Math.cos(tipRadians)}
          stroke="white"
          strokeWidth="5"
          style={{ transition: 'fill 0.2s ease-in-out' }}
          className={colorClass('fill', score)}
        />
        <circle
          r="7"
          cy={radius + (radius - 12) * Math.sin(tipRadians)}
          cx={radius + (radius - 12) * Math.cos(tipRadians)}
          fill="white"
        />
      </g>
    );
  }

  return (
    <div className={score === 0 ? 'security-grade disabled' : 'security-grade'}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          r={radius - 12}
          cx={radius}
          cy={radius}
          className={'svg-stroke-gray'}
          strokeWidth="24"
          strokeDasharray={`${circumference * (215 / 360)}, ${
            circumference * (145 / 360)
          }`}
          strokeLinecap="round"
          style={{ transformOrigin: '50%', transform: 'rotate(150deg)' }}
          fill="none"
        ></circle>
        {scoreArc}
      </svg>
      {children}
    </div>
  );
};

SecurityGradeArc.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
};

export const ratingScore = (rating) => {
  switch (rating) {
    case 'F':
      return 20;
    case 'D':
      return 45;
    case 'C':
      return 60;
    case 'B':
      return 80;
    case 'A':
      return 95;
    case 'A+':
      return 100;
    default:
      return 0;
  }
};

const SecurityGradeLetter = (props) => {
  let score = '\uFF1F'; // unicode for question mark (?)
  let scoreClass = 'letter';

  if (props.rating) {
    score = props.rating;
  }

  if (score === '\uFF1F') {
    scoreClass = 'letter score-unknown';
  }

  return <div className={scoreClass}>{score}</div>;
};

SecurityGradeLetter.propTypes = {
  rating: PropTypes.string,
};

const SecurityGradeCaption = () => {
  return (
    <span className="caption">
      <FormattedMessage id="sitecard__security_grade" />
    </span>
  );
};

const SecurityGrade = (props) => {
  const score = Math.min(100, Math.max(0, ratingScore(props.rating)));
  return (
    <ScoreContext.Provider value={score}>
      <SecurityGradeArc {...props}>{props.children}</SecurityGradeArc>
    </ScoreContext.Provider>
  );
};

SecurityGrade.propTypes = {
  children: PropTypes.node,
  rating: PropTypes.string,
  size: PropTypes.number,
};

export { SecurityGradeArc, SecurityGradeCaption, SecurityGradeLetter };

export default SecurityGrade;
