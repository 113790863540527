import { isEmpty } from '../../common/helpers';
import { statuses } from './product-pivot';

/**
 * Returns the site's Backups message
 *
 * @param {Object} backups Site Backups details.
 * @param {Object} latestBackup Last backup details.
 * @param {Boolean} latestBackupLoading Indicates loading of latest backup.
 * @param {Boolean} loading Indicates whether list sites with backup endpoint is loading.
 *
 * @returns {Object} Backups message
 */
const useBackupsStatusMessage = ({
  backups,
  latestBackup,
  latestBackupLoading,
  loading,
}) => {
  const isSiteBackupActivated = !isEmpty(backups);

  if (loading || latestBackupLoading) {
    return {
      description: 'common__loading',
      status: statuses.OK,
    };
  }

  if (!isSiteBackupActivated) {
    return {
      description: 'common__not_activated',
      status: statuses.WARNING,
    };
  }

  // MWP backups are on but we don't latest backup.
  if (isEmpty(latestBackup)) {
    return {
      description: 'common__not_activated',
      status: statuses.WARNING,
    };
  }

  // MWP backups are on and latest backup failed.
  if (latestBackup && latestBackup.state === 'error') {
    return {
      description: 'sitecard__backup_failed',
      status: statuses.ERROR,
    };
  }

  const isBackupsEnabled = backups?.backupEnabled;

  if (isBackupsEnabled && backups.paused) {
    return {
      description: 'sitecard__backup_paused',
      status: statuses.WARNING,
    };
  }

  if (isBackupsEnabled) {
    return {
      description: 'sitecard__activated',
      status: statuses.OK,
    };
  }

  if (backups.createdAt) {
    // if backup is not enabled but has createdAt, assume initial backup is inprogress
    // ref: https://godaddy-corp.atlassian.net/l/cp/dwq1VmLf
    return {
      description: 'sitecard__in_progress',
      status: statuses.LOADING,
    };
  }

  return {
    description: 'sitecard__backup_not_enabled',
    status: statuses.WARNING,
  };
};

export { useBackupsStatusMessage };
