import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@ux/spinner';

export const StatusSpinner = (props) => {
  if (props.loading) {
    return (
      <div data-testid="spinner" className="text-center pivot-spinner-wrapper">
        <Spinner size="md" />
      </div>
    );
  }

  return props.children;
};
StatusSpinner.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default StatusSpinner;
