import { isEmpty } from '../../common/helpers';
import { warningStates } from '../services/monitoring';
import { statuses } from './product-pivot';

/**
 * Returns the site's Monitoring message
 *
 * @param {Object} monitoring Site Monitoring details
 *
 * @returns {Object} Monitoring message
 */
const useMonitoringStatusMessage = (monitoring) => {
  const {
    isNewMonitoringScan,
    isLastMonitoringScanSuccessful,
    isBlacklisted,
    isInfected,
    isOutdated,
  } = warningStates(monitoring);

  if (isEmpty(monitoring)) {
    return {
      description: 'sitecard__not_detected',
      status: statuses.UNKNOWN,
    };
  }

  if (isNewMonitoringScan) {
    return {
      description: 'sitecard__new_scan',
      status: statuses.LOADING,
    };
  }

  if (monitoring.loading || !isLastMonitoringScanSuccessful) {
    return {
      description: 'sitecard__scan_failed',
      status: statuses.ERROR,
    };
  }

  if (isBlacklisted) {
    return {
      description: 'sitecard__site_is_blacklisted',
      status: statuses.ERROR,
    };
  }

  if (isInfected) {
    return {
      description: 'sitecard__site_is_infected',
      status: statuses.ERROR,
    };
  }

  if (isOutdated) {
    return {
      description: 'sitecard__site_is_outdated',
      status: statuses.WARNING,
    };
  }

  return {
    description: 'sitecard__activated',
    status: statuses.OK,
  };
};

export { useMonitoringStatusMessage };
