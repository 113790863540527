import punycode from 'punycode/';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import { FormattedMessage } from 'react-intl';
import SettingsIcon from '@ux/icon/settings';
import { useIntl } from 'react-intl';

import { logger } from '@/common/initAPM';
import { siteHasValidSubscription } from '@/helpers/ApiHelper';
import { isEmpty } from '@/common/helpers';
import { isApiReseller } from '@/helpers/ApiHelper';
import {
  DEFAULT_ICON_WIDTH,
  DEFAULT_ICON_HEIGHT,
  DEFAULT_ICON_COLOR,
} from '@/common/constants';

import SecurityGrade, { SecurityGradeLetter } from '../security-grade';
import SitecheckTimeAgo from '../sitecheck-time-ago';
import PlanSettingsModal from './settings-modal';
import MonitoringStatus from './monitoring-status';
import MSSLStatus from './mssl-status';
import { useSiteNextStep } from '../services/site-nextstep';
import BackupsStatus from './backups-status';
import FirewallStatus from './firewall-status';
import Track, { events } from '../track';
import SiteCardNotification from './site-card-notification';
import BuyWSS from './buy-wss';
import SubscriptionsList from '../services/subscriptions/subscriptions-list';
import { getLetterScore } from '../services/site-rating';

const SiteCard = ({
  domainData,
  siteSubscriptions,
  monitoring,
  firewall,
  backups,
  ssl,
  rating,
  hostingProducts,
  msslProducts,
  error,
  subscriptions,
  support,
}) => {
  const intl = useIntl();
  const domain = punycode.toUnicode(domainData.domain);
  const [latestBackup, setLatestBackup] = useState(null);
  const [showCard, setShowCard] = useState(true);
  const [siteCardRating, setSiteCardRating] = useState(null);
  const [latestBackupLoading, setLatestBackupLoading] = useState(false);
  const nextstep = useSiteNextStep({
    domain,
    monitoring,
    firewall,
    backups,
    ssl,
    domainData,
    error,
    subscriptions,
    latestBackup,
    latestBackupLoading,
    support,
  });
  const [showModal, setShowModal] = useState(false);
  const inactiveWSSProducts = subscriptions.data.withWebSecurity().inactive();

  const cardClasses =
    'col-xs-12 d-flex flex-column flex-lg-row justify-content-center';

  useEffect(() => {
    if (
      isEmpty(backups.backups) ||
      backups.loading ||
      !isEmpty(latestBackup) ||
      !backups.backups.id ||
      latestBackupLoading
    )
      return;

    setLatestBackupLoading(true);
    // Avoid loading Backups site data if backups are not enabled
    if (backups?.backups?.backupEnabled && backups?.backups?.backupSetting) {
      fetch(`/api/backups/sites/${backups.backups.id}/latest`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          throw response;
        })
        .then((json) => {
          setLatestBackup(json);
        })
        .catch((err) => {
          logger.error(err);
        })
        .finally(() => {
          setLatestBackupLoading(false);
        });
    } else {
      setLatestBackupLoading(false);
    }
  }, [backups.loading]);

  useEffect(() => {
    // if the site is on backups 2.0, recalculate the site score using latestBackup
    if (latestBackup) {
      const scoreParams = {
        monitoring: monitoring.monitoring,
        backups: backups.backups,
        firewall: firewall.firewall,
        ssl: ssl.ssl,
        mssl: ssl.mssl,
        subscriptions: siteSubscriptions,
        loading: latestBackupLoading,
        error: error,
        latestBackup: latestBackup,
      };

      const updatedSiteRating = getLetterScore({ ...scoreParams });

      setSiteCardRating(updatedSiteRating);
    }
  }, [backups.server, latestBackup]);

  useEffect(() => {
    if (rating) {
      setSiteCardRating(rating);
    }
  }, [rating]);

  return (
    <>
      {showModal && (
        <PlanSettingsModal
          onClose={setShowModal}
          onRemovePlan={setShowCard}
          subscriptions={siteSubscriptions}
          domain={domain}
        />
      )}
      {showCard && (
        <div
          className="col-sm-12 site-card"
          data-testid={`site-card-${domainData.domain}`}
        >
          <div className="col-12 card ux-card mb-3">
            <div className="card-block p-4">
              <div className="row d-flex flex-column">
                <div className="col-sm-12 d-flex justify-content-between align-items-center mb-4">
                  <h2
                    className="h4 font-weight-bold mb-0 domain-name"
                    data-testid={`site-card-heading-${domainData.domain}`}
                  >
                    {domain}
                  </h2>
                  {!isApiReseller() && (
                    <Track id={events.MYSITES_SITECARD_SETTINGS}>
                      <Button
                        design="inline"
                        className="pl-0 pr-0"
                        onClick={() => setShowModal(true)}
                        data-testid={'sec-dash-settings-button'}
                        aria-label={intl.formatMessage({
                          id: 'common__settings',
                        })}
                        icon={
                          <SettingsIcon
                            color={DEFAULT_ICON_COLOR}
                            width={DEFAULT_ICON_WIDTH}
                            height={DEFAULT_ICON_HEIGHT}
                          />
                        }
                      />
                    </Track>
                  )}
                </div>
                <div className="col-sm-12 mb-md-5 pivot-container">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className={cardClasses}>
                      <div className="d-flex flex-column text-center security-grade-container mr-md-4">
                        <SecurityGrade rating={siteCardRating}>
                          <div>
                            <SecurityGradeLetter rating={siteCardRating} />
                            <span className="caption">
                              <FormattedMessage
                                id={'sitecard__security_grade'}
                              />
                            </span>
                          </div>
                        </SecurityGrade>
                        <div className="mb-0 mt-n4">
                          <SitecheckTimeAgo
                            date={new Date(monitoring.last_check)}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-column flex-lg-row">
                        {siteHasValidSubscription(
                          siteSubscriptions,
                          'monitoring',
                        ) ? (
                          <MonitoringStatus
                            {...monitoring}
                            domainData={domainData}
                          />
                        ) : (
                          <BuyWSS
                            product="monitoring"
                            subscriptions={inactiveWSSProducts}
                            domainData={domainData}
                          />
                        )}
                      </div>
                      <div className="d-flex flex-column flex-lg-row">
                        {siteHasValidSubscription(
                          siteSubscriptions,
                          'firewall',
                        ) ? (
                          <FirewallStatus
                            domain={domain}
                            {...firewall}
                            error={Boolean(error?.firewall)}
                            domainData={domainData}
                            subscriptions={subscriptions}
                          />
                        ) : (
                          <BuyWSS
                            product="firewall"
                            subscriptions={inactiveWSSProducts}
                            domainData={domainData}
                          />
                        )}
                      </div>
                      <div className="d-flex flex-column flex-lg-row">
                        <MSSLStatus
                          {...ssl}
                          domainData={domainData}
                          hostingProducts={hostingProducts}
                          msslProducts={msslProducts}
                          subscriptions={subscriptions}
                        />
                      </div>
                      <div className="d-flex flex-column flex-lg-row">
                        {siteHasValidSubscription(
                          siteSubscriptions,
                          'backups',
                        ) ? (
                          <BackupsStatus
                            id={`${domain}-backup-status`}
                            domain={domain}
                            domainData={domainData}
                            latestBackup={latestBackup}
                            latestBackupLoading={latestBackupLoading}
                            {...backups}
                          />
                        ) : (
                          <BuyWSS
                            product="backups"
                            subscriptions={inactiveWSSProducts}
                            domainData={domainData}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <SiteCardNotification
                  modal={nextstep.modal}
                  modalProps={nextstep.modalProps}
                  link={nextstep.link}
                  action={nextstep.action}
                  buttonDesign={nextstep.buttonDesign}
                  rating={siteCardRating}
                >
                  <div className="text-md-left text-center mb-md-0 mb-2 site-card-notification">
                    <h6 className="h5 font-weight-bold mb-0">
                      <FormattedMessage
                        id={
                          nextstep.nextStepSubtitle
                            ? nextstep.nextStepSubtitle
                            : 'sitecard__next_step'
                        }
                      />
                    </h6>
                    {isApiReseller() ||
                      (nextstep.action !== 'sitecard__request_support' && (
                        <p className="mb-0">
                          <FormattedMessage
                            id={nextstep.description}
                            values={nextstep.translationValues}
                          />
                        </p>
                      ))}

                    {!isApiReseller() &&
                      nextstep.action === 'sitecard__request_support' && (
                        <p className="mb-0">
                          <FormattedMessage
                            id={nextstep.description}
                            values={{
                              a: (chunks) => (
                                <Track
                                  id={events.MYSITES_SITECARD_SUPPORT_WAF_LEARN}
                                >
                                  <a
                                    href={`https://www.godaddy.com/help/set-up-a-firewall-40156`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {chunks}
                                  </a>
                                </Track>
                              ),
                            }}
                          />
                        </p>
                      )}
                  </div>
                </SiteCardNotification>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SiteCard.propTypes = {
  domainData: PropTypes.object.isRequired,
  monitoring: PropTypes.object,
  firewall: PropTypes.object,
  ssl: PropTypes.object,
  backups: PropTypes.object,
  error: PropTypes.object,
  siteSubscriptions: PropTypes.array,
  hostingProducts: PropTypes.array,
  msslProducts: PropTypes.array,
  rating: PropTypes.string,
  subscriptions: PropTypes.shape({
    data: PropTypes.instanceOf(SubscriptionsList),
    loading: PropTypes.bool,
    error: PropTypes.string,
    invalidateCache: PropTypes.func,
  }),
};

export default SiteCard;
export { SiteCard as SiteCardWithoutStore };
