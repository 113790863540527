/* eslint-disable max-statements */
import React from 'react';
import { withAuthRequired } from '@godaddy/gasket-auth';
import { withLocaleRequired } from '@gasket/react-intl';
import { injectIntl, IntlShape } from 'react-intl';
import Growl from '@ux/growl';
import PropTypes from 'prop-types';
import Alert from '@ux/alert';
import axios from 'axios';

import { logger } from '@/common/initAPM';
import { useQualtricsMetrics } from '@/common/hooks/use-qualtrics-metric';

import ssLogger from '../server/logger';
import Head from '../components/head';
import MySites from '../components/my-sites';
import SPAQElement from '../components/spaq-element';
import { isMSSL } from '../helpers/ApiHelper';
import Error from '../components/custom-error';
import intents from '../common/intents-config';
import { formatTimestamp } from '../helpers/ApiHelper';

const idx = require('idx');

const IndexPage = (props) => {
  const {
    intl,
    initialMsslTickets,
    initialSubscriptions,
    offlineMode,
    motdMode,
    motdDetails,
  } = props;

  if (props.authDetails) {
    logger.setUserContext(props.authDetails);
  }

  useQualtricsMetrics();

  return (
    <div className="container pt-5 pb-5">
      <Growl />
      <Head title={intl.formatMessage({ id: 'common__website_security' })} />
      <SPAQElement />{' '}
      {/* Do not remove - used by SPAQ to check for secui availabality */}
      {!offlineMode && motdMode && (
        <div className="mb-3">
          <Alert
            id="maintenance-message"
            emphasis="neutral"
            title={intl.formatMessage({ id: 'common__maintenance_notice' })}
          >
            <div className={'mt-3'}>
              {intl.formatMessage(
                {
                  id:
                    motdDetails?.messageId ||
                    'common__maintenance_schedule_datetime',
                },
                {
                  datetime: formatTimestamp(idx(motdDetails, (_) => _.start)),
                },
              )}
            </div>
          </Alert>
        </div>
      )}
      {!offlineMode && (
        <MySites
          initialMsslTickets={initialMsslTickets}
          initialSubscriptions={initialSubscriptions}
        />
      )}
      {offlineMode && (
        <Error
          customTitleId="common__maintenance"
          customSubtitleId="common__maintenance_brb"
          customIconColor={intents.ux.feedbackHighContrastWarning.feedbackColor}
          showBackToHomeButton={false}
        />
      )}
    </div>
  );
};

IndexPage.propTypes = {
  intl: PropTypes.shape(IntlShape),
  initialMsslTickets: PropTypes.array,
  initialSubscriptions: PropTypes.array,
  offlineMode: PropTypes.bool,
  motdMode: PropTypes.bool,
  motdDetails: PropTypes.object,
  authDetails: PropTypes.object,
};

const getInitialSubscriptions = async (ctx) => {
  const subs = [];
  let total = 0;
  let offset = 0;

  try {
    do {
      const newParams = new URLSearchParams({
        productGroupKeys:
          'websiteSecurity,sslCerts,hosting,wordpress,domains,servers,websiteBuilder',
        includes: 'addons',
        offset: offset,
        limit: 250,
      });

      const response = await axios.get(
        `${
          ctx.req.config.base_api_endpoint
        }/subscriptions?${newParams.toString()}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Market-Id': ctx.req.cookies.market,
            Authorization: `sso-jwt ${ctx.req.cookies.auth_idp}`,
          },
        },
      );

      if (response?.data.subscriptions) {
        subs.push(...response.data.subscriptions);
      }

      total = parseInt(response.data?.pagination?.total, 10);
      offset += 250;
    } while (subs.length < total);
  } catch (err) {
    ssLogger(__filename).error(
      `Error fetching subscriptions - ${err?.message}`,
      {
        req: ctx.req,
        res: ctx.res,
      },
    );
  }

  return subs;
};

const getInitialMSSLTickets = async (ctx) => {
  let initialMsslTickets = [];
  const msslResponse = await fetch(
    `${ctx.req.config.mssl_api_endpoint}/tickets?subIdNotNull=1`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'sso-jwt ' + ctx.req.cookies.auth_idp,
        'User-Agent': 'SECUI-NEXTJS',
      },
      timeout: 5000,
    },
  ).catch((err) => {
    ssLogger(__filename).error(
      `Error fetching MSSL tickets - ${err?.message}`,
      {
        req: ctx.req,
        res: ctx.res,
      },
    );
  });

  if (msslResponse && msslResponse.ok) {
    const msslJson = await msslResponse.json();
    initialMsslTickets = msslJson && msslJson.list;
  }

  return initialMsslTickets;
};

export async function getServerSideProps(ctx) {
  // set offline mode
  const offlineMode =
    idx(ctx.req, (_) => _.config.switchboard.secui_offline_mode.active) ===
    true;

  // set motd mode
  const motdMode =
    idx(ctx.req, (_) => _.config.switchboard.secui_motd_mode.active) === true;
  const motdDetails =
    idx(ctx.req, (_) => _.config.switchboard.secui_motd_mode) || {};

  ctx.res.setHeader('Cache-Control', 'public,  max-age=0, must-revalidate');

  const initialSubscriptions = await getInitialSubscriptions(ctx);
  let initialMsslTickets = [];

  const hasMsslSub = initialSubscriptions.find(
    (sub) => sub.product && isMSSL(sub.product.pfid),
  );

  if (hasMsslSub) {
    initialMsslTickets = await getInitialMSSLTickets(ctx);
  }

  ssLogger(__filename).info('Customer product groups', {
    req: ctx.req,
    res: ctx.res,
    labels: {
      wss: initialSubscriptions.some(
        (sub) => sub.product.productGroupKey === 'websiteSecurity',
      ),
      mssl: initialMsslTickets.length > 0,
      pki: initialSubscriptions.some(
        (sub) => sub.product.productGroupKey === 'sslCerts',
      ),
    },
  });

  return {
    props: {
      initialSubscriptions,
      initialMsslTickets,
      offlineMode,
      motdMode,
      motdDetails,
    },
  };
}

export { IndexPage };

export default withAuthRequired({ realm: 'idp', initialProps: false })(
  withLocaleRequired('/locales', { initialProps: false })(
    injectIntl(IndexPage),
  ),
);
