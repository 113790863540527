import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@ux/dropdown';
import { FormattedMessage } from 'react-intl';

import { trackEvent, events } from '../helpers/analytics';
import { ratingScore } from './security-grade';

const { DropdownItem } = Dropdown;

function invert(comparator) {
  return function (left, right) {
    return -1 * comparator(left, right);
  };
}

const ascendingComparators = {
  name: function (left, right) {
    return left.domain.localeCompare(right.domain);
  },
  score: function (left, right) {
    const leftScore = ratingScore(left.letterScore);
    const rightScore = ratingScore(right.letterScore);
    if (leftScore < rightScore) {
      return -1;
    }
    return leftScore > rightScore ? 1 : 0;
  },
};

const descendingComparators = Object.keys(ascendingComparators).reduce(
  function (acc, key) {
    acc[key] = invert(ascendingComparators[key]);
    return acc;
  },
  {},
);

export default function sort({ onChange, types }) {
  const raiseChange = useCallback(
    function (index) {
      const { comparator, name } = types[index];
      onChange(function (products) {
        return products.slice().sort(comparator);
      }, name);
    },
    [onChange, types],
  );

  const [current, setCurrent] = useState();

  const onClick = useCallback(
    function (index) {
      const { name } = types[index];
      trackEvent(events.MYSITES_SORT_CHANGE, 'click', { sort: name.props?.id });
      setCurrent(index);
      raiseChange(index);
    },
    [trackEvent, setCurrent, raiseChange],
  );

  // SECUE-711: set current in useEffect to prevent console warnings
  useEffect(() => {
    setCurrent(() => {
      // Force a change so we ensure a good sort for initial render
      raiseChange(sort.defaultSelectedIndex);
      return sort.defaultSelectedIndex;
    });
  }, []);

  return (
    <Dropdown
      id="sortDropdown"
      type="select"
      onChange={(p) => onClick(p.itemIndex)}
      placeholder={<FormattedMessage id="common__sort_sites" />}
      selected={[current]}
    >
      {types.map(({ name }, index) => (
        <DropdownItem key={index}>{name}</DropdownItem>
      ))}
    </Dropdown>
  );
}

sort.propTypes = {
  onChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      comparator: PropTypes.func.isRequired,
    }),
  ),
};

sort.defaultProps = {
  types: [
    {
      name: <FormattedMessage id="sort__securitygrade_ascending" />,
      comparator: ascendingComparators.score,
    },
    {
      name: <FormattedMessage id="sort__securitygrade_descending" />,
      comparator: descendingComparators.score,
    },
    {
      name: <FormattedMessage id="sort__sitename_ascending" />,
      comparator: ascendingComparators.name,
    },
    {
      name: <FormattedMessage id="sort__sitename_descending" />,
      comparator: descendingComparators.name,
    },
  ],
};

// Expose out defaults on a static property
sort.defaultComparators = {
  ascendingComparators,
  descendingComparators,
};

sort.defaultSelectedIndex = 0;
