import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { data as uxdata } from '@ux/data';
import ShieldIcon from '@ux/icon/shield';

import { DEFAULT_ICON_HEIGHT, DEFAULT_ICON_WIDTH } from '@/common/constants';
import { getURLPathWithQueryParams, isEmpty } from '@/common/helpers';
import {
  generateProductRedirectURL,
  isApiReseller,
  isGoDaddy,
  isHostedInternally,
  isWSSU,
  pfidHasBackup,
  pfidHasWAF,
  pfidHasWSS,
  siteHasValidSubscription,
} from '@/helpers/ApiHelper';
import { FirewallVersion } from '@/common/enums';

import { warningStates } from './monitoring';
import { ConfigContext } from '../config';
import SelectSubscriptionModal from '../select-subscriptions-modal';
import LoadingModal from '../loading-modal';
import * as pfids from '../services/subscriptions/pfids';
import { useFlag, SECUI_SUPPORT_MGMT_1, SECUI_SUPPORT_V2 } from '../flags';
import intents from '../../common/intents-config';
import RequestCleanupModal from '../support/request-cleanup-modal';
import CreateTicket from '../support/create-ticket';

const shieldIcon = (
  <ShieldIcon
    color={intents.ux.action.foregroundColor}
    width={DEFAULT_ICON_WIDTH}
    height={DEFAULT_ICON_HEIGHT}
  />
);

/**
 * Returns the site's next step as per its monitoring, firewall and backups data
 *
 * @param {Object} monitoring Site Monitoring details
 * @param {Object} backups Site backups details
 * @param {Object} firewall Site Firewall details
 *
 * @returns {Object} Site's next step
 */
const useSiteNextStep = ({
  domain,
  monitoring,
  backups,
  firewall,
  ssl,
  domainData,
  support,
  error,
  subscriptions,
  latestBackup,
  latestBackupLoading,
}) => {
  const intl = useIntl();
  const config = useContext(ConfigContext);

  const isSupportMgmt1Enabled = useFlag(SECUI_SUPPORT_MGMT_1);
  const isSupportV2Enabled = useFlag(SECUI_SUPPORT_V2);
  const isWaf2Customer = firewall?.firewall?.version === FirewallVersion.TWO;

  const monitoringData = monitoring && monitoring.monitoring;
  const backupsData = backups && backups.backups;
  const firewallData = firewall?.firewall;

  const inactiveWSSProducts = subscriptions.data.withWebSecurity().inactive();
  const inactiveSubscriptions = subscriptions.data.inactive();
  const hostingProducts = subscriptions.data.withHostingProducts();
  const inactiveBackupSubscriptions = inactiveSubscriptions.filter(
    (subscription) => {
      return pfidHasBackup(subscription.product.pfid);
    },
  );

  let messageCount = 0;
  if (ssl && ssl.mssl && ssl.mssl.tickets && ssl.mssl.tickets) {
    ssl.mssl.tickets.forEach((t) => {
      messageCount += parseInt(t.unread_messages, 10) || 0;
    });
  }

  const hasMSSLAgentMessageWaiting = messageCount > 0;

  const isSSLActivated = !isEmpty(ssl.ssl) || !isEmpty(ssl.mssl.tickets);

  let WSSUltimateSubs = [];
  let unusedSSLSubs = [];
  let WSSSubs = [];
  if (domainData && domainData.subscriptions) {
    WSSSubs = domainData.subscriptions.filter((s) => {
      if (s && s.product && s.product.pfid) {
        if (pfidHasWSS(s.product.pfid)) {
          return s;
        }
      }
    });

    WSSUltimateSubs = WSSSubs.filter((s) => {
      if (s && s.product && s.product.pfid) {
        return isWSSU(s.product.pfid);
      }
    });

    unusedSSLSubs = domainData.subscriptions.filter((s) => {
      if (s && s.product && s.product.pfid && !s.label) {
        return pfids.STANDARD_SSL.has(s.product.pfid);
      }
    });
  }

  const isOnWSSUPlan = WSSUltimateSubs.length > 0;
  const hasInactiveSSL = unusedSSLSubs.length > 0;
  const hasWSS = WSSSubs.length > 0;
  const hasInactiveWSS = inactiveWSSProducts && inactiveWSSProducts.length > 0;
  const activeWAFSubscription = domainData.subscriptions?.find((s) =>
    pfidHasWAF(s.product.pfid),
  );

  const domainIsHostedInternally = isHostedInternally(domain, hostingProducts);

  const handleWAFNextStep = () => {
    if (isWaf2Customer && activeWAFSubscription) {
      const query = {
        domain,
        subscriptionId: activeWAFSubscription?.externalId,
        siteId: firewallData.siteId,
      };

      return `/setup/firewall?${new URLSearchParams(query)}`;
    }

    return generateProductRedirectURL('firewall', domain);
  };

  const {
    isLastMonitoringScanSuccessful,
    isBlacklisted,
    isInfected,
    isOutdated,
  } = warningStates(monitoringData);

  const isFirewallActivated = firewallData?.active;
  const isSiteBackupActivated = !isEmpty(backupsData);
  const isSiteBackupLastRunSuccessful =
    latestBackup && latestBackup.state === 'completed';
  const isInitalBackupInProgress =
    isSiteBackupActivated &&
    !backupsData.backupEnabled &&
    !!backupsData.createdAt;

  if (
    monitoring?.loading ||
    backups?.loading ||
    latestBackupLoading ||
    firewall?.loading
  ) {
    return {
      description: 'common_loading_details',
      link: '',
      action: 'unknown',
      backgroundClass: 'product-faint',
    };
  }

  if (!hasWSS && !hasInactiveWSS && isGoDaddy()) {
    return {
      description: 'sitecard__buy_wss',
      link: 'https://www.godaddy.com/web-security/website-security',
      action: 'sitecard__buy',
    };
  }

  if (isEmpty(monitoringData) && hasInactiveWSS) {
    return {
      description: 'sitecard__activate_wss',
      action: 'setup_btn_text',
      modal: SelectSubscriptionModal,
      modalProps: {
        subscriptions: inactiveWSSProducts,
        domainData: domainData,
        titleId: 'sitecard__activate_wss_modal_title',
        productIcon: shieldIcon,
      },
    };
  }

  if (
    isEmpty(monitoringData) ||
    !isEmpty(error.monitoring) ||
    !isLastMonitoringScanSuccessful
  ) {
    return {
      description: 'sitecard__check_website_is_online',
      link: `/site/${
        monitoringData.domain === domain && monitoringData.siteId
      }/monitoring/security`,
      action: 'sitecard__learn_more',
      modal: LoadingModal,
      modalProps: {
        setProductName: intl.formatMessage({ id: 'common__monitoring' }),
        maxSeconds: 8,
      },
    };
  }

  if (isBlacklisted || isInfected) {
    if (isSupportV2Enabled && isInfected) {
      const tickets = support?.tickets ?? [];

      const ticket = tickets.find((ticket) => ticket.ticket_type === 'malware');
      const ticketId = ticket?.ticket_id;

      const hastTicket = Boolean(ticketId);

      if (hastTicket) {
        return {
          description: 'support_expect_reply',
          link: `/support/${ticketId}`,
          action: 'support__view_support_ticket',
          buttonDesign: 'secondary',
          nextStepSubtitle: 'support_clean_up_in_progress',
          translationValues: {
            ticket: ticketId,
          },
        };
      }

      const cleanupModalProps = {
        site: domain,
        issueType: 'malware',
      };

      const ticketModalProps = {
        show: true,
        domains: [domain],
        defaultValues: {
          2: {
            site: domain,
          },
        },
      };

      return {
        description: 'sitecard__submit_support_ticket',
        action: 'request_cleanup_btn',
        modal: domainIsHostedInternally ? RequestCleanupModal : CreateTicket,
        modalProps: domainIsHostedInternally
          ? cleanupModalProps
          : ticketModalProps,
      };
    }

    if (isSupportMgmt1Enabled) {
      if (
        !isEmpty(support) &&
        Object.prototype.hasOwnProperty.call(support, 'ticket')
      ) {
        return {
          description: 'support_waiting_time',
          link: `/support?action=view_ticket&ticket=${support.ticket}`,
          action: 'support__view_support_ticket',
          buttonDesign: 'link',
          nextStepSubtitle: 'support_clean_up_in_progress',
        };
      }

      return {
        description: 'sitecard__submit_support_ticket',
        link: '/support?action=create_new&domain=' + domain,
        action: 'request_cleanup_btn',
      };
    }

    return {
      description: 'sitecard__submit_support_ticket',
      link: generateProductRedirectURL('support-malware', domain),
      action: 'request_cleanup_btn',
      modal: LoadingModal,
      modalProps: {
        setProductName: intl.formatMessage({ id: 'common__support' }),
        maxSeconds: 8,
      },
    };
  }

  if (isOutdated) {
    return {
      description: 'sitecard__update_the_software_on_your_site',
      link: `/site/${
        monitoringData.domain === domain && monitoringData.siteId
      }/monitoring/security`,
      action: 'sitecard__learn_more',
      modal: LoadingModal,
      modalProps: {
        setProductName: intl.formatMessage({ id: 'common__monitoring' }),
        maxSeconds: 8,
      },
    };
  }

  // Link to backups overview page
  if (backupsData && isSiteBackupActivated && isEmpty(latestBackup)) {
    return {
      description: 'backups_complete_configuration',
      link: getURLPathWithQueryParams(
        `/site/${backupsData.id}/backups?action=setup`,
      ),
      action: 'sitecard__activate_backups',
    };
  }

  // Link to backups overview page
  if (backupsData && isSiteBackupActivated && !isSiteBackupLastRunSuccessful) {
    return {
      description: 'sitecard__investigate_why_last_backup_failed',
      link: `/site/${backupsData.id}/backups`,
      action: 'sitecard__review_logs',
    };
  }

  if (!isSiteBackupActivated && !isEmpty(inactiveBackupSubscriptions)) {
    return {
      description: 'sitecard__enable_backups',
      action: 'sitecard__activate_backups',
      modal: SelectSubscriptionModal,
      modalProps: {
        subscriptions: inactiveBackupSubscriptions,
        domainData: domainData,
        titleId: 'sitecard__activate_wss_modal_title',
        productIcon: shieldIcon,
      },
    };
  }

  if (error && !isEmpty(error.firewall)) {
    return {
      description: 'firewall__support_learn_more',
      link: generateProductRedirectURL('support', domain),
      action: 'sitecard__request_support',
      modal: LoadingModal,
      modalProps: {
        setProductName: intl.formatMessage({ id: 'common__firewall' }),
        maxSeconds: 8,
      },
    };
  }

  if (
    !isFirewallActivated &&
    siteHasValidSubscription(domainData.subscriptions, 'firewall')
  ) {
    return {
      description: 'sitecard__enable_firewall',
      link: handleWAFNextStep(),
      action: 'sitecard__activate_firewall',
      modal: LoadingModal,
      modalProps: {
        setProductName: intl.formatMessage({ id: 'common__firewall' }),
        maxSeconds: 8,
      },
    };
  }

  if (!isSSLActivated && hasInactiveSSL && !isOnWSSUPlan && !isApiReseller()) {
    const externalId = unusedSSLSubs[0].externalId || '';

    return {
      description: 'sitecard__enable_your_ssl',
      link:
        config &&
        config.certs_site &&
        `${config.certs_site}/cert/mya?certificateId=${externalId}`,
      action: 'sitecard__activate_ssl',
    };
  }

  if (hasMSSLAgentMessageWaiting) {
    return {
      description: 'sitecard__reply_to_agent',
      link: `/ticket?id=${ssl.mssl.tickets[0].id}`,
      action: 'reply',
    };
  }

  if (isInitalBackupInProgress) {
    return {
      description: 'sitecard__backup_in_progress_initial',
    };
  }

  if (!isApiReseller) {
    if (!isEmpty(firewallData) || isEmpty(backupsData)) {
      return {
        description: 'sitecard__upgrade_to_improve_score',
        link: `${uxdata.urls.home.href}/web-security/website-security`,
        action: 'common__upgrade',
      };
    }
  }

  return {
    description: 'sitecard__all_products_activated',
    nextStepSubtitle: 'common__success',
    link: '',
    action: 'done',
  };
};

export { useSiteNextStep };
