import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { data as uxdata } from '@ux/data';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import OkIcon from '@ux/icon/ok';
import LockedIcon from '@ux/icon/locked';
import CommentIcon from '@ux/icon/comment';

import {
  pfidHasWAF,
  isApiReseller,
  isProReseller,
  isGoDaddy,
  hasMWPSSL,
} from '@/helpers/ApiHelper';
import {
  DEFAULT_ICON_WIDTH,
  DEFAULT_ICON_HEIGHT,
  DEFAULT_ICON_COLOR,
} from '@/common/constants';

import { ConfigContext } from '../config';
import ProductPivot, { statuses } from './product-pivot';
import {
  useFlag,
  SECUI_PRODUCTS_API_WAF_V2,
  SECUI_MSSL_MAINTENANCE,
} from '../flags';
import LoadingModal from '../loading-modal';
import SelectSubscriptionModal from '../select-subscriptions-modal';
import { events } from '../track';
import intents from '../../common/intents-config';

const { redirectToLegacyDashboard } = require('../../helpers/ApiHelper');

const lockedIcon = (
  <LockedIcon
    color={DEFAULT_ICON_COLOR}
    width={DEFAULT_ICON_WIDTH}
    height={DEFAULT_ICON_HEIGHT}
  />
);

export const MsslMessageNotification = (props) => {
  const IconComponent = props.messageCount > 0 ? CommentIcon : OkIcon;

  return (
    <div className="mssl-messages-notification d-flex justify-content-center">
      <IconComponent
        color={intents.ux.action.foregroundColor}
        width={DEFAULT_ICON_WIDTH}
        height={DEFAULT_ICON_HEIGHT}
      />
      {props.messageCount > 0 && (
        <div className="mssl-messages-count-wrapper bg-danger-base bd-a-2 bd-white">
          <p className="mssl-messages-count text-white">{props.messageCount}</p>
        </div>
      )}
    </div>
  );
};
MsslMessageNotification.propTypes = {
  messageCount: PropTypes.number,
};

const MSSLStatus = ({
  loading,
  mssl,
  ssl,
  msslProducts,
  domainData,
  subscriptions,
}) => {
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingError, setLoadingError] = useState('');
  const [showMSSLSelectModal, setshowMSSLSelectModal] = useState(false);
  const router = useRouter();
  const isInternalMgmtOn = useFlag(SECUI_PRODUCTS_API_WAF_V2);
  const isMsslMaintenanceMode = useFlag(SECUI_MSSL_MAINTENANCE);
  const config = useContext(ConfigContext);
  const intl = useIntl();

  if (router && router.beforePopState) {
    router.beforePopState(() => {
      setShowLoadingModal(false);
      return true;
    });
  }

  const WSSWafSubs = domainData?.subscriptions?.filter((s) =>
    pfidHasWAF(s && s.product && s.product.pfid),
  );
  let MWPWithSSLSubs = [];

  if (subscriptions && subscriptions.data) {
    MWPWithSSLSubs = subscriptions.data.filter((s) => {
      return (
        hasMWPSSL(s && s.product && s.product.pfid) &&
        s.label &&
        s.label.split(', ').includes(domainData.domain)
      );
    });
  }

  const fireWallEnabled = domainData.firewall?.active;
  const siteMsslProducts = msslProducts.filter((m) => {
    return m.label === domainData.domain;
  });
  const hasMsslProducts =
    msslProducts.length > 0 && siteMsslProducts.length > 0;

  let pivotProps = {
    status: statuses.UNKNOWN,
  };

  if (loading) {
    pivotProps = {
      ...pivotProps,
      status: statuses.LOADING,
      statusTextID: 'common__loading',
    };
  } else if (mssl.tickets.length >= 1) {
    let messageCount = 0;
    let status = '';
    mssl.tickets.forEach((t) => {
      messageCount += parseInt(t.unread_messages, 10) || 0;
      status = t.status;
    });
    pivotProps.extra = {
      messageCount,
    };

    if (mssl.tickets.length >= 1) {
      pivotProps.onClick = () => {
        const query = {
          id: mssl.tickets[0].id,
        };

        if (router?.query?.plid) {
          query.plid = router.query?.plid;
        }

        router.push({
          pathname: '/ticket',
          query,
        });
      };
    }

    switch (status) {
      case 'open':
        pivotProps.status =
          messageCount > 0 ? statuses.MESSAGES : statuses.PENDING;
        pivotProps.statusTextID =
          messageCount > 0
            ? 'msslstatus__new_messages'
            : 'msslstatus__setup_in_progress';
        break;
      case 'onhold':
        pivotProps.status = statuses.WARNING;
        pivotProps.statusTextID = 'msslstatus__setup_on_hold';
        break;
      case 'closed':
        pivotProps.status = statuses.OK;
        pivotProps.statusTextID = mssl.tickets[0].one_time_service
          ? 'mssl__service_label'
          : 'common__website_managedssl';
        break;
      default:
        pivotProps.status = statuses.WARNING;
        pivotProps.statusTextID = 'common__not_activated';
    }
  } else if (ssl.length > 0) {
    pivotProps = {
      ...pivotProps,
      status: statuses.OK,
      statusTextID: 'common__activated',
      buttonTextID: 'common__details',
      onClick: () => {
        window.location.assign(
          `${
            isGoDaddy() ? config.certs_site : config.certs_site_resellers
          }/cert/mya?certificateId=${ssl[0].externalId}`,
        );
      },
    };

    if (isApiReseller()) {
      pivotProps = {
        ...pivotProps,
        hideButton: true,
      };
    }
  } else if (MWPWithSSLSubs.length > 0) {
    const handleManage = () => {
      const mwpHost =
        isApiReseller() || isProReseller()
          ? config.mwp_hosting_reseller_endpoint
          : config.mwp_hosting_endpoint;
      window.location.assign(
        `${mwpHost}/mwp/site/${MWPWithSSLSubs[0].externalId}/overview`,
      );
    };

    pivotProps = {
      ...pivotProps,
      status: statuses.OK,
      statusTextID: 'msslstatus__included_in_ultimate_mwp',
      onClick: handleManage,
    };
  } else if (WSSWafSubs?.length > 0 && fireWallEnabled) {
    const handleManage = () => {
      if (domainData.firewall?.siteId && isInternalMgmtOn) {
        router.push(
          '/site/[id]/firewall',
          `/site/${domainData.firewall?.siteId}/firewall`,
        );
      } else {
        setShowLoadingModal(true);
        redirectToLegacyDashboard({
          product: 'firewall',
          domain: domainData.domain,
          callback: () => {
            setLoadingError(<FormattedMessage id="manage_token_error" />);
          },
          query: null,
          close: () => {
            setShowLoadingModal(false);
          },
        });
      }
    };

    pivotProps = {
      ...pivotProps,
      status: statuses.OK,
      statusTextID: 'msslstatus__included_in_firewall',
      onClick: handleManage,
    };
  } else if (isMsslMaintenanceMode) {
    pivotProps = {
      ...pivotProps,
      status: statuses.PENDING,
      statusTextID: 'common__temporarily_unavailable',
      hideButton: true,
    };
  } else if (mssl.tickets.length === 0) {
    pivotProps = {
      ...pivotProps,
      status: hasMsslProducts ? statuses.WARNING : statuses.PURCHASE,
      statusTextID: hasMsslProducts
        ? 'common__not_activated'
        : 'sitecard__missing',
      buttonTextID: hasMsslProducts ? 'setup_btn_text' : 'sitecard__buy',
      customEvent: hasMsslProducts
        ? null
        : events.MYSITES_SITECARD_BUYVSCONTENT_EXP,
      onClick: () => {
        if (hasMsslProducts) {
          setshowMSSLSelectModal(true);
          return;
        }

        if (isGoDaddy()) {
          window.location.assign(
            `${uxdata.urls.home.href}/web-security/easy-ssl-service`,
          );
        } else {
          const urlObject = new URL(uxdata.urls.home.href);
          window.location.assign(
            `${urlObject.origin}/products/ssl-managed${urlObject.search}`,
          );
        }
      },
    };

    if (isApiReseller()) {
      pivotProps = {
        ...pivotProps,
        hideButton: true,
      };
    }
  }

  return (
    <>
      {showMSSLSelectModal && (
        <SelectSubscriptionModal
          onClose={() => setshowMSSLSelectModal(false)}
          subscriptions={msslProducts}
          domainData={domainData}
          titleId="domain__card_select_mssl_product"
          productIcon={lockedIcon}
          event={events.MYSITES_MSSL_SETUP_START}
        />
      )}
      {showLoadingModal && (
        <LoadingModal
          onClose={() => {
            setShowLoadingModal(false);
            setLoadingError(null);
          }}
          productName={intl.formatMessage({ id: 'common__firewall' })}
          maxSeconds={8}
          error={loadingError}
        />
      )}
      <ProductPivot
        product="mssl"
        rating={domainData.letterScore}
        {...pivotProps}
      />
    </>
  );
};
MSSLStatus.propTypes = {
  loading: PropTypes.bool.isRequired,
  mssl: PropTypes.object.isRequired,
  ssl: PropTypes.array.isRequired,
  domainData: PropTypes.object,
  hostingProducts: PropTypes.array,
  msslProducts: PropTypes.array,
  subscriptions: PropTypes.object,
};

MSSLStatus.defaultProps = {
  loading: false,
  hostingProducts: [],
  msslProducts: [],
};

export default MSSLStatus;
