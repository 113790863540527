import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MySitesTitle = ({ sites }) => {
  return (
    <h1 className="h1 headline-brand mb-0" data-testid={'sec-dash-sites-count'}>
      <FormattedMessage id={'mysites__title'} />{' '}
      {sites.length > 0 ? `(${sites.length})` : ''}
    </h1>
  );
};
MySitesTitle.propTypes = {
  sites: PropTypes.array,
};

export default MySitesTitle;
