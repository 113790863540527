import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import { useIntl } from 'react-intl';
import OkIcon from '@ux/icon/ok';

import { isEmpty } from '../../common/helpers';
import Track, { events } from '../track';
import {
  DEFAULT_ICON_WIDTH,
  DEFAULT_ICON_HEIGHT,
} from '../../common/constants';
import { useFlag, SECUI_SUPPORT_V2 } from '../flags';

const ActionButton = ({
  buttonDesign = 'primary',
  action,
  link,
  rating,
  onAction,
}) => {
  const intl = useIntl();
  const isSupportV2Enabled = useFlag(SECUI_SUPPORT_V2);

  if (!action || action === 'unknown') {
    return null;
  }

  if (action === 'done') {
    return <OkIcon width={DEFAULT_ICON_WIDTH} height={DEFAULT_ICON_HEIGHT} />;
  }

  const defaultButtonProps = {
    design: buttonDesign,
    onClick: onAction,
    text: intl.formatMessage({ id: action }),
  };

  if (action === 'sitecard__request_support') {
    return (
      <Track id={events.MYSITES_SITECARD_SUPPORT_WAF_REQUEST}>
        <Button href={link} {...defaultButtonProps} />
      </Track>
    );
  }

  if (action === 'request_cleanup_btn' && isSupportV2Enabled) {
    return (
      <Track id={events.MYSITES_SITECARD_REQUEST_CLEANUP_CLICK}>
        <Button
          design="secondary"
          onClick={onAction}
          text={intl.formatMessage({ id: action })}
        />
      </Track>
    );
  }

  return (
    <Track
      id={
        action !== 'sitecard__review_logs'
          ? events.MYSITES_SITECARD_NEXTSTEP_CLICK
          : events.MYSITES_SITECARD_NEXTSTEP_BACKUPS_CLICK
      }
      data={{ rating: rating }}
    >
      <Button
        design={buttonDesign}
        onClick={onAction}
        href={link}
        text={intl.formatMessage({ id: action })}
      />
    </Track>
  );
};

const SiteCardNotification = ({
  children,
  modal: ModalComponent,
  modalProps = {},
  action,
  link,
  buttonDesign = 'primary',
  rating,
}) => {
  const [displayModal, setDisplayModal] = useState(false);

  const onClose = () => {
    setDisplayModal(false);
  };

  const handleActionClick = (e) => {
    e.preventDefault();

    const showModal = Boolean(ModalComponent);
    if (!showModal) {
      return;
    }

    setDisplayModal(true);
  };

  if (displayModal && !isEmpty(modalProps)) {
    return <ModalComponent {...modalProps} onClose={onClose} />;
  }

  return (
    <div className="col-sm-12">
      <div className="notification d-flex flex-md-row flex-column justify-content-md-between justify-content-center p-4 align-items-center bg-faint rounded">
        {children}
        <ActionButton
          buttonDesign={buttonDesign}
          action={action}
          link={link}
          rating={rating}
          onAction={handleActionClick}
        />
      </div>
    </div>
  );
};

SiteCardNotification.propTypes = {
  children: PropTypes.any,
  modal: PropTypes.any,
  action: PropTypes.any,
  link: PropTypes.any,
  modalProps: PropTypes.any,
  buttonDesign: PropTypes.any,
  rating: PropTypes.any, // sometimes this is null
};

export default SiteCardNotification;
