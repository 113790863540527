import { useState } from 'react';
import Button from '@ux/button';
import { FormattedMessage, IntlShape } from 'react-intl';
import PropTypes from 'prop-types';
import TrashIcon from '@ux/icon/trash';
import formatDistance from 'date-fns/formatDistance';

const PlanSettingsCard = ({ plan, onRemovePlan, intl }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [error, setError] = useState('');

  const warningClass = showWarning
    ? 'justify-content-start flex-column'
    : 'justify-content-between align-items-center';

  const remove = async () => {
    setSuccess(false);
    setFailure(false);
    setInProgress(true);

    const { error: removeError } = await onRemovePlan();
    if (removeError) {
      setInProgress(false);
      setFailure(true);
      setSuccess(false);
      setError(removeError.error || '');
      return;
    }

    setInProgress(false);
    setSuccess(true);
    setFailure(false);
  };

  const reset = () => {
    setShowWarning(false);
    setInProgress(false);
    setSuccess(false);
    setFailure(false);
    setError('');
  };

  if (success) {
    return (
      <div className="col-12 card ux-card mt-3 mb-0">
        <div className="card-block p-4">
          <FormattedMessage
            id="plansettingsmodal__success"
            values={{
              b: (...chunks) => (
                <span className="font-weight-bold">{chunks}</span>
              ),
              product: plan.product.label,
            }}
          />
        </div>
      </div>
    );
  }

  const now = new Date();
  const expiresAt = new Date(plan.expiresAt);

  let expirationMsg;
  if (isNaN(expiresAt.getTime())) {
    expirationMsg = intl.formatMessage({
      id: 'plansettingsmodal__no_subscription',
    });
  } else if (now > expiresAt) {
    expirationMsg = intl.formatMessage(
      { id: 'plansettingsmodal__expired' },
      {
        date: formatDistance(new Date(expiresAt), new Date(), {
          addSuffix: true,
        }),
      },
    );
  } else {
    expirationMsg = intl.formatMessage(
      { id: 'plansettingsmodal__expires' },
      {
        date: formatDistance(new Date(expiresAt), new Date(), {
          addSuffix: true,
        }),
      },
    );
  }

  return (
    <div
      className="col-12 card ux-card mt-3 mb-0"
      data-testid={'sec-plan-settings-card'}
    >
      <div className={`card-block p-4 d-flex ${warningClass}`}>
        {!showWarning && (
          <div>
            <h6
              className="font-weight-bold mb-1"
              data-testid={'sec-plan-settings-card-label'}
            >
              {plan.product.label}
            </h6>
            <p className="mb-0">{expirationMsg}</p>
          </div>
        )}
        {showWarning && (
          <div>
            <h6 className="font-weight-bold mb-1 text-danger">
              <FormattedMessage id="plansettingsmodal__confirm_heading" />
            </h6>
            <p className="mb-0 text-danger">
              <FormattedMessage id="plansettingsmodal__confirm_body" />
            </p>
          </div>
        )}
        {!showWarning && (
          <Button
            design="inline"
            className="pl-0 pr-0"
            onClick={() => setShowWarning(true)}
            data-testid={'sec-plan-settings-card-trash-button'}
            aria-label={intl.formatMessage({ id: 'plansettingsmodal__button' })}
            icon={<TrashIcon width={24} height={24} />}
          />
        )}

        {showWarning && (
          <>
            <div className="mt-3">
              {!inProgress && (
                <>
                  <Button
                    design="critical"
                    onClick={remove}
                    data-testid={'sec-plan-settings-card-confirm-remove-button'}
                    text={intl.formatMessage({
                      id: 'plansettingsmodal__button',
                    })}
                  />
                  <Button
                    design="primary"
                    className="ml-5"
                    onClick={reset}
                    text={intl.formatMessage({ id: 'common__cancel' })}
                  />
                </>
              )}
              {inProgress && (
                <>
                  <Button
                    disabled
                    design="critical"
                    text={intl.formatMessage({
                      id: 'plansettingsmodal__button_inprogress',
                    })}
                  />
                </>
              )}
            </div>
            {failure && (
              <div className="mt-3 text-danger font-weight-bold">
                <FormattedMessage
                  id="plansettingsmodal__error"
                  values={{ error }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

PlanSettingsCard.propTypes = {
  plan: PropTypes.object.isRequired,
  onRemovePlan: PropTypes.func.isRequired,
  intl: PropTypes.shape(IntlShape),
};

export default PlanSettingsCard;
