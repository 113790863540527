import { useState, useMemo, useContext } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import Growl from '@ux/growl';
import Modal from '@ux/modal';
import { data as uxdata } from '@ux/data';
import AlertIcon from '@ux/icon/alert';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FirewallVersion,
  FirewallStatus as FirewallStatusEnum,
} from '@/common/enums';
import { SECUI_WAF_CDN_UPGRADE } from '@/common/flags';
import { useFlag } from '@/components/flags';
import { isWAFPlanUpgradable } from '@/components/firewall/shared/helpers';

import {
  activateWAF,
  hasMWPSSL,
  isApiReseller,
  isProReseller,
  pfidHasWAF,
} from '../../helpers/ApiHelper';
import WSSSetupSuccessResultRow from '../modal/wss-setup/wss-setup-success-result-row';
import LoadingModal from '../loading-modal';
import ProductPivot, { statuses } from './product-pivot';
import intents from '../../common/intents-config';
import useFirewallSites from '../services/firewall';
import { redirectToLegacyDashboard } from '../../helpers/ApiHelper';
import { ConfigContext } from '../config';

const { addGrowlMessage } = Growl;
const FIREWALL_STATIC_IP = '89.119.180.24';
const PROVIDERS = {
  HOSTGATOR: 'Hostgator',
};

const handleBuy = () => {
  window.location.assign(
    `${uxdata && uxdata.urls.home.href}/web-security/website-security`,
  );
};

const SetupModal = ({ show, setShow, onActivateManually }) => {
  const intl = useIntl();

  if (!show) {
    return null;
  }

  const waf = {
    show: true,
    icon: (
      <AlertIcon
        color={intents.ux.feedbackWarning.feedbackColor}
        width={55}
        height={55}
      />
    ),
    header: <FormattedMessage id="firewall__cannot_activate" />,
    description: <FormattedMessage id="activate_manually" />,
    textColor: 'wss-setup-result-text-color-pass',
    descColor: 'wss-setup-result-text-color-pass',
  };

  return (
    <Modal
      id="setup-modal"
      title={intl.formatMessage({ id: 'common__firewall' })}
      onClose={() => {
        setShow(false);
      }}
    >
      <div className="status-modal-container">
        <WSSSetupSuccessResultRow
          icon={waf.icon}
          header={waf.header}
          description={waf.description}
          textColorClass={waf.textColor}
          descriptionColorClass={waf.descColor}
        />
        <div className="wss-setup-button-row">
          <Button
            className="float-left"
            onClick={onActivateManually}
            design="primary"
            type="submit"
            text={intl.formatMessage({ id: 'activate_firewall' })}
          />
          <Button
            design="inline"
            className="wss-cleanup-close ml-4"
            onClick={() => {
              setShow(false);
            }}
            text={intl.formatMessage({ id: 'close' })}
          />
          <div className="float-clear"></div>
        </div>
      </div>
    </Modal>
  );
};
SetupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onActivateManually: PropTypes.func.isRequired,
};

const ActivateFirewallModal = ({
  show,
  onClose,
  domain,
  isExternal = false,
}) => {
  const intl = useIntl();

  if (!show) {
    return null;
  }

  return (
    <Modal
      id="activate-firewall-modal"
      title={intl.formatMessage({ id: 'sitecard__activate_firewall' })}
      onClose={onClose}
    >
      {isExternal && (
        <>
          <FormattedMessage
            id="firewall__activate_firewall_external_provider"
            values={{ provider: PROVIDERS.HOSTGATOR }}
            tagName={Text.p}
          />
          <FormattedMessage
            id="firewall__activate_firewall_external_provider_change_self"
            values={{ ip: FIREWALL_STATIC_IP }}
            tagName={Text.p}
          />
          <div>
            <Button
              text={intl.formatMessage(
                { id: 'firewall__activate_firewall_login_provider' },
                { provider: PROVIDERS.HOSTGATOR },
              )}
              design="primary"
            />
            <Button
              text={intl.formatMessage({ id: 'common__cancel' })}
              className="ml-3"
              onClick={onClose}
            />
          </div>
        </>
      )}
      {!isExternal && (
        <>
          <FormattedMessage
            id="firewall__activate_firewall_please_change_dns"
            values={{
              site: domain,
              ip: FIREWALL_STATIC_IP,
              br: <br />,
              b: (chunks) => <b>{chunks}</b>,
              a: (chunks) => <Button text={chunks} design="inline" />,
            }}
            tagName={Text.p}
          />
          <Button
            text={intl.formatMessage({ id: 'common__ok' })}
            design="primary"
            onClick={onClose}
          />
        </>
      )}
    </Modal>
  );
};
ActivateFirewallModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
};

const FirewallStatus = ({
  loading,
  domain,
  firewall,
  domainData,
  subscriptions,
  error,
}) => {
  const intl = useIntl();
  const router = useRouter();

  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showActivateFirewall, setShowActivateFirewall] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [isFirewallActivating, setIsFirewallActivating] = useState(false);
  const [loadingError, setLoadingError] = useState('');

  const firewallSites = useFirewallSites();
  const config = useContext(ConfigContext);
  const WAFCDNUpgradeFlag = useFlag(SECUI_WAF_CDN_UPGRADE);

  // If this is unknown, SecUI was unable to determine the WAF version for the user
  const wafVersion =
    firewall?.version || firewallSites?.data?.version || 'unknown';

  const isWaf2Customer = wafVersion === FirewallVersion.TWO;

  let MWPWithSSLSubs = [];

  if (subscriptions && subscriptions.data) {
    MWPWithSSLSubs = subscriptions.data.filter((s) => {
      return (
        hasMWPSSL(s.product?.pfid) &&
        s.label &&
        s.label?.split(', ').includes(domainData.domain)
      );
    });
  }
  const isMWPSite = firewall.planId === 'CDNWithWAF';

  if (router && router.beforePopState) {
    router.beforePopState(() => {
      setShowLoadingModal(false);
      return true;
    });
  }

  const handleActivate = async () => {
    setIsFirewallActivating(true);
    const result = await activateWAF(domain);
    setIsFirewallActivating(false);
    if (result.success === true) {
      firewallSites.invalidateCache();
      addGrowlMessage({
        title: 'Success',
        id: 'firewall-activation-message',
        children: 'Activation complete',
        emphasis: 'success',
        lifetime: 2000,
      });
    } else {
      setShowSetupModal(true);
    }
  };

  const handleV2Actions = (restart = false) => {
    const subscription = domainData.subscriptions?.find((s) =>
      pfidHasWAF(s.product.pfid),
    );

    // Append SiteId, domain, subscription to preload steps.
    const query = {
      domain,
      subscriptionId: subscription?.externalId,
      siteId: domainData?.firewall?.siteId,
    };

    if (restart) {
      query.action = 'restart';
    }

    router.push({
      pathname: '/setup/firewall',
      query,
    });
  };

  const handleActivateClick = async (restart = false) => {
    if (isWaf2Customer) {
      // If customer is WAF 2.0 then activate Firewall 2.0
      handleV2Actions(restart);
    } else {
      // If customer is legacy then activate Firewall 1.0 (Sucuri)
      await handleActivate();
    }
  };

  const handleManage = () => {
    if (isWaf2Customer && firewall.siteId) {
      router.push('/site/[id]/firewall', `/site/${firewall.siteId}/firewall`);
      return;
    }

    setShowSetupModal(false);
    setShowLoadingModal(true);
    redirectToLegacyDashboard({
      product: 'firewall',
      domain,
      callback: () => {
        setLoadingError(<FormattedMessage id="manage_token_error" />);
      },
      query: null,
      close: () => {
        setShowLoadingModal(false);
      },
    });
  };

  const handleMWPWAFDetails = () => {
    const subscription = domainData?.subscriptions?.find((s) =>
      pfidHasWAF(s.product.pfid),
    );

    if (
      subscription &&
      WAFCDNUpgradeFlag &&
      firewall?.siteId &&
      [FirewallStatusEnum.SUCCESS, FirewallStatusEnum.LIVE].includes(
        firewall.status,
      )
    ) {
      router.push('/site/[id]/firewall', `/site/${firewall.siteId}/firewall`);
      return;
    }

    const mwpHost =
      isApiReseller() || isProReseller()
        ? config.mwp_hosting_reseller_endpoint
        : config.mwp_hosting_endpoint;
    window.location.assign(
      `${mwpHost}/mwp/site/${MWPWithSSLSubs[0].externalId}/overview`,
    );
  };

  const pivotProps = useMemo(() => {
    const props = {
      status: statuses.UNKNOWN,
      statusTextID: '',
    };

    if (error) {
      props.status = statuses.ERROR;
      props.statusTextID = 'sitecard__loading_failed';
      props.buttonTextID = 'sitecard__loading_failed';
      props.hideButton = true;
      props.buttonDisabled = true;

      return props;
    }

    if (loading) {
      props.status = statuses.LOADING;
      props.statusTextID = 'common__loading';
      props.buttonTextID = 'please_wait';
    } else if (isFirewallActivating) {
      props.status = statuses.LOADING;
      props.statusTextID = 'common__activating';
      props.buttonTextID = 'please_wait';
      props.hideButton = true;
    } else if (isMWPSite) {
      if (!domainData.subscriptions?.find((s) => pfidHasWAF(s.product.pfid))) {
        props.status = statuses.OK;
        props.statusTextID = 'msslstatus__included_in_ultimate_mwp';
        props.buttonTextID = 'common__details';
      } else if (isWAFPlanUpgradable(firewall)) {
        props.status = statuses.UPGRADE;
        props.statusTextID = 'sitecard__activate_wss_firewall';
        props.buttonTextID = 'setup_btn_text';
      } else {
        props.status = statuses.OK;
        props.statusTextID = 'sitecard__activated';
        props.buttonTextID = 'common__details';
      }
      // This handles all MWP interactions
      props.onClick = handleMWPWAFDetails;
    } else if (
      !firewall?.active &&
      !firewall?.siteId &&
      !subscriptions?.data?.find((s) => pfidHasWAF(s.product.pfid))
    ) {
      props.status = statuses.UNKNOWN;
      props.statusTextID = 'common__not_in_this_plan';
      props.buttonTextID = 'common__upgrade';
      props.onClick = handleBuy;
      props.hideButton = isApiReseller();
    } else if (
      !firewall.active &&
      ['PENDING', 'SUCCESS'].includes(firewall.status)
    ) {
      props.status = statuses.PENDING;
      props.statusTextID = 'sitecard__in_progress';
      props.hideButton = false;
      props.buttonTextID = 'common__continue';
      props.onClick = () => handleActivateClick();
    } else if (firewall.status === 'FAILED') {
      props.status = statuses.ERROR;
      props.statusTextID = 'sitecard__waf_setup_failed';
      props.hideButton = false;
      props.buttonTextID = 'firewall__activate_retry';
      props.onClick = () => handleActivateClick(true);
    } else if (firewall.active) {
      props.status = statuses.OK;
      props.statusTextID = 'sitecard__activated';
      props.buttonTextID = 'common__details';
      props.onClick = handleManage;
    } else if (
      subscriptions?.data?.some((s) => pfidHasWAF(s.product.pfid)) &&
      !firewall?.active &&
      !firewall.siteId
    ) {
      props.status = statuses.WARNING;
      props.statusTextID = 'common__not_activated';
      props.buttonTextID = 'setup_btn_text';
      props.onClick = () => handleActivateClick();
    }

    // When Firewall is disabled but Active on the site.
    if (
      firewall?.bypassWAF === 'enabled' &&
      firewall.status === 'SUCCESS' &&
      firewall.active
    ) {
      props.status = statuses.WARNING;
      props.statusTextID = 'common__disabled';
    }

    // IF a plan is not associated with Firewall Button should be inactive.
    if (
      !domainData?.subscriptions?.find((s) => pfidHasWAF(s.product.pfid)) &&
      firewall.active &&
      firewall.bypassWAF &&
      firewall?.bypassWAF !== 'enabled'
    ) {
      props.status = statuses.PENDING;
      props.statusTextID = 'sitecard__in_progress';
      props.hideButton = false;
      props.buttonTextID = 'common__continue';
      props.buttonDisabled = true;
    }

    return props;
  }, [loading, isFirewallActivating, firewall]);

  const handleActiveFirewallClose = () => {
    setShowActivateFirewall(false);
  };

  return (
    <>
      <SetupModal
        show={showSetupModal}
        setShow={setShowSetupModal}
        onActivateManually={handleManage}
      />
      <ActivateFirewallModal
        show={showActivateFirewall}
        onClose={handleActiveFirewallClose}
        domain={domain}
      />
      {showLoadingModal && (
        <LoadingModal
          onClose={() => {
            setShowLoadingModal(false);
            setLoadingError(null);
          }}
          productName={intl.formatMessage({ id: 'common__firewall' })}
          maxSeconds={8}
          error={loadingError}
        />
      )}

      {pivotProps && (
        <ProductPivot
          product="firewall"
          rating={domainData.letterScore}
          version={wafVersion}
          {...pivotProps}
        />
      )}
    </>
  );
};
FirewallStatus.propTypes = {
  loading: PropTypes.bool.isRequired,
  domain: PropTypes.string.isRequired,
  firewall: PropTypes.object.isRequired,
  domainData: PropTypes.object.isRequired,
  subscriptions: PropTypes.object,
};

export default FirewallStatus;
