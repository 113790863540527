import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

/*
 * ExperimentWrapper Component - renders the experiment views based on the assigned cohortId
 *
 * @param {object} cohortsViews - an object containing the cohortIds and the corresponding views
 * @param {string} experimentId - the experiment id
 *
 * @example - <ExperimentWrapper cohortsViews={{on: <TestA/>, off: <TestB/> }} experimentId='secui_experiment_a' />
 *
 *  @returns {React.Component} - the component
 */
const ExperimentWrapper = ({ cohortsViews, experimentId }) => {
  const cohort = useSelector((state) => state.hivemind.cohorts[experimentId]);

  const cohortView =
    cohort?.cohortId && cohortsViews && cohortsViews[cohort.cohortId];

  return <>{cohortView && cohortView}</>;
};

ExperimentWrapper.propTypes = {
  cohortsViews: PropTypes.object,
  experimentId: PropTypes.string,
};

export default ExperimentWrapper;
