import { data as uxdata } from '@ux/data';
import Button from '@ux/button';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Track, { events } from './track';

const BuyAPlanButton = ({ eventId = events.MYSITES_BUY_NOW_BUTTON_CLICK }) => {
  const intl = useIntl();

  if (typeof uxdata === 'undefined' || uxdata.privateLabelId !== 1) {
    return null;
  }

  return (
    <Track id={eventId}>
      <Button
        role="link"
        href={`${uxdata.urls.home.href}/web-security/website-security`}
        target="_blank"
        id="buy-a-plan-btn"
        design="primary"
        className="ml-md-3 d-flex d-sm-inline-block flex-grow-1 flex-grow-sm-0 text-center"
        text={intl.formatMessage({ id: 'noplans__button' })}
      />
    </Track>
  );
};

BuyAPlanButton.propTypes = {
  eventId: PropTypes.string,
};

export default BuyAPlanButton;
