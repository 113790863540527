import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Alert from '@ux/alert';
import PropTypes from 'prop-types';

const Errors = ({ errors }) => {
  const [errorMessageIds, setErrorMessageIds] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    const tempIds = [];

    for (const key in errors) {
      if (
        Object.prototype.hasOwnProperty.call(errors, key) &&
        key !== 'any' &&
        errors[key]
      ) {
        let messageId = null;
        switch (key) {
          case 'backups':
            messageId = 'mysites__error_backups';
            break;
          case 'monitoring':
            messageId = 'mysites__error_monitoring';
            break;
          case 'subscriptions':
            messageId = 'mysites__error_subscriptions';
            break;
          case 'mssl':
            messageId = 'mysites__error_mssl';
            break;
          case 'firewall':
            messageId = 'mysites__error_firewall';
            break;
          default:
        }

        if (messageId && !tempIds.includes(messageId)) {
          tempIds.push(messageId);
        }
      }
    }

    setErrorMessageIds([...tempIds]);
  }, [errors]);

  return errorMessageIds.map((mid) => (
    <Alert
      id="mysites-error"
      title={intl.formatMessage({ id: mid })}
      key={mid}
      emphasis="warning"
    />
  ));
};

Errors.propTypes = {
  errors: PropTypes.object,
};

export default Errors;
