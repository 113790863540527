import Button from '@ux/button';
import Spinner from '@ux/spinner';
import Text from '@ux/text';
import Growl from '@ux/growl';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRef, useState } from 'react';
import {
  ActionMessage,
  FullScreenTakeover,
  IpAddrDialog,
  PublicKeyDialog,
  useUpdate,
} from '@secui/support';

const RequestCleanupModal = ({ site, issueType, onClose }) => {
  const intl = useIntl();
  const modalRef = useRef();

  const [showPublicKey, setShowPublicKey] = useState(false);
  const [showIpAddr, setShowIpAddr] = useState(false);

  const [createTicket, createTicketResult] = useUpdate(`/api/support/tickets`);

  const handleClick = async () => {
    try {
      const payload = {
        ticket_type: issueType,
        site,
        tags: ['mlw-ok'],
      };

      await createTicket(payload);
      Growl.addGrowlMessage({
        emphasis: 'success',
        title: intl.formatMessage({ id: 'support__ticket_created' }),
        id: 'ticket-create',
        children: intl.formatMessage({
          id: 'support__view_ticket_in_details_page',
        }),
        lifetime: 3000,
      });
      onClose();
    } catch {
      Growl.addGrowlMessage({
        emphasis: 'critical',
        title: intl.formatMessage({ id: 'common__oops' }),
        id: 'ticket-create',
        children: intl.formatMessage({ id: 'support__ticket_create_failed' }),
      });
    }
  };

  return (
    <FullScreenTakeover onClose={onClose}>
      <div ref={modalRef} />
      <PublicKeyDialog
        show={showPublicKey}
        onClose={() => setShowPublicKey(false)}
        portalElem={modalRef.current}
      />
      <IpAddrDialog
        show={showIpAddr}
        onClose={() => setShowIpAddr(false)}
        portalElem={modalRef.current}
      />
      <div className="mt-5">
        <Text.h1
          size={5}
          as="title"
          className="font-weight-normal mb-5"
          text={intl.formatMessage({ id: 'support__request__cleanup' })}
        />
        <FormattedMessage
          id="support__submit_ticket__selected_site_confirmation"
          values={{ site: site }}
          tagName={Text.p}
        />
        <ActionMessage
          id="support__submit_ticket__view_public_key"
          onClick={() => setShowPublicKey(true)}
        />
        <ActionMessage
          id="support__submit_ticket__allow_ip"
          onClick={() => setShowIpAddr(true)}
        />
        <FormattedMessage
          tagName={Text.p}
          id="support__submit_ticket__file_modify_consent"
        />
        <Button
          design="primary"
          className="mt-2"
          icon={createTicketResult.isLoading ? <Spinner size="sm" /> : null}
          disabled={createTicketResult.isLoading}
          text={intl.formatMessage({ id: 'support__request__submit' })}
          onClick={handleClick}
        />
      </div>
    </FullScreenTakeover>
  );
};

export default RequestCleanupModal;
