import React from 'react';
import PropTypes from 'prop-types';

import { MonitorsVersion } from '@/common/enums';

import { useMonitoringStatusMessage } from './monitoring-messsage';
import ProductPivot, { statuses } from './product-pivot';

const MonitoringStatus = ({ monitoring, loading, domainData }) => {
  const message = useMonitoringStatusMessage(monitoring);

  const monitorsVersion =
    domainData?.monitoring?.version ||
    monitoring.version ||
    MonitorsVersion.UNKNOWN;

  const handleClick = () => {
    // Monitors2.0
    if (
      monitorsVersion === MonitorsVersion.TWO &&
      domainData.monitoring?.siteId
    ) {
      window.location.href = `/site/${domainData.monitoring.siteId}/monitoring/security`;
      return;
    }
  };

  return (
    <>
      <ProductPivot
        product="monitoring"
        status={loading ? statuses.LOADING : message.status}
        statusTextID={message.description}
        onClick={handleClick}
        rating={domainData.letterScore}
        version={monitorsVersion}
      />
    </>
  );
};

MonitoringStatus.propTypes = {
  domain: PropTypes.string.isRequired,
  monitoring: PropTypes.object.isRequired,
  domainData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MonitoringStatus;
