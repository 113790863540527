import React from 'react';
import Button from '@ux/button';
import ChatIcon from '@ux/icon/chat';
import { useIntl } from 'react-intl';

import events from '../../common/events';
import { trackEvent } from '../../helpers/analytics';

const SupportButton = (props) => {
  const intl = useIntl();

  const redirectToSupport = () => {
    trackEvent(events.WSS_GLOBAL_SUPPORT_BUTTON_CLICK, 'click', {
      event: 'wss_global_support_button_click',
    });
    window.open('/_redirect/support/new', '_blank').focus();
  };

  return (
    <>
      <Button
        text={intl.formatMessage({ id: 'common__request_support' })}
        design="secondary"
        icon={<ChatIcon />}
        onClick={redirectToSupport}
        {...props}
      />
    </>
  );
};

SupportButton.propTypes = {};

export default SupportButton;
