import { useState } from 'react';
import PropTypes from 'prop-types';
import { data as uxdata } from '@ux/data';
import ShieldIcon from '@ux/icon/shield-check';

import { DEFAULT_ICON_HEIGHT, DEFAULT_ICON_WIDTH } from '@/common/constants';
import {
  isApiReseller,
  isGoDaddy,
  siteHasValidSubscription,
} from '@/helpers/ApiHelper';

import ProductPivot, { statuses } from './product-pivot';
import SelectSubscriptionModal from '../select-subscriptions-modal';
import { events } from '../track';
import intents from '../../common/intents-config';

const shieldIcon = (
  <ShieldIcon
    color={intents.ux.action.foregroundColor}
    width={DEFAULT_ICON_WIDTH}
    height={DEFAULT_ICON_HEIGHT}
  />
);

const BuyWSS = ({ product, subscriptions, domainData }) => {
  const [showModal, setShowModal] = useState(false);

  const hasRequiredProduct = siteHasValidSubscription(subscriptions, product);

  const buttonTextId = hasRequiredProduct ? 'setup_btn_text' : 'sitecard__buy';
  const statusTextID = hasRequiredProduct
    ? 'common__not_activated'
    : 'sitecard__missing';
  const status = hasRequiredProduct ? statuses.WARNING : statuses.PURCHASE;
  const event = hasRequiredProduct
    ? null
    : events.MYSITES_SITECARD_BUY_PRODUCT_CLICK;

  if (!isGoDaddy()) {
    return null;
  }

  const onClose = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    if (hasRequiredProduct) {
      setShowModal(true);
      return;
    }

    if (!isGoDaddy()) {
      return;
    }

    window.location = uxdata.urls.home.href + '/web-security/website-security';
  };

  return (
    <>
      <ProductPivot
        product={product}
        status={status}
        statusTextID={statusTextID}
        buttonTextID={buttonTextId}
        hideButton={isApiReseller()}
        onClick={handleClick}
        rating={domainData.letterScore}
        customEvent={event}
      />
      {showModal && (
        <SelectSubscriptionModal
          subscriptions={subscriptions}
          domainData={domainData}
          titleId="sitecard__activate_wss_modal_title"
          productIcon={shieldIcon}
          onClose={onClose}
        />
      )}
    </>
  );
};

BuyWSS.propTypes = {
  product: PropTypes.string.isRequired,
  subscriptions: PropTypes.array,
  domainData: PropTypes.object,
};

export default BuyWSS;
