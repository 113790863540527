import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlShape, useIntl } from 'react-intl';
import { data } from '@ux/data';
import Alert from '@ux/alert';

/**
 * @param {object} props
 * @param {boolean} props.subscriptions loading state
 * @param {boolean} props.mssl loading state
 * @param {boolean} props.monitoring loading state
 * @param {boolean} props.firewall loading state
 * @param {boolean} props.backups loading state
 * @param {object} props.intl react intl
 * @returns {React.ReactNode} a loading message element
 */

const LoadingMessage = (props) => {
  const intl = useIntl();
  const { subscriptions, mssl, monitoring, firewall, backups } = props;
  const [firstLoading, setFirstLoading] = useState('');
  const [totalLoading, setTotalLoading] = useState(0);
  const [loadingMessage, setLoadingMessageId] = useState({
    id: 'common__loading',
  });
  const [isLoading, setIsLoading] = useState(true);

  const names = {
    subscriptions: intl.formatMessage(
      { id: 'common__private_label_accounts' },
      { private_label_name: data.privateLabelName },
    ),
    monitoring: intl.formatMessage({ id: 'common__website_monitoring' }),
    firewall: intl.formatMessage({ id: 'common__website_firewall' }),
    backups: intl.formatMessage({ id: 'common__website_backups' }),
    mssl: intl.formatMessage({ id: 'common__website_managedssl' }),
  };

  const getLoadingMessage = () => {
    const others = Math.max(0, totalLoading - 1);

    if (isLoading) {
      if (others === 0) {
        setLoadingMessageId({
          id: 'loadingmessage__loading_from',
          values: { source: firstLoading },
        });
      }

      if (others > 0) {
        setLoadingMessageId({
          id: 'loadingmessage__loading_from_other',
          values: { source: firstLoading, count: others },
        });
      }
    } else {
      setLoadingMessageId({ id: 'loadingmessage__all_loaded', values: null });
    }
  };

  useEffect(() => {
    let tempTotal = 0;

    for (const key in names) {
      if (Object.prototype.hasOwnProperty.call(props, key)) {
        const prop = props[key];
        if (prop) {
          setFirstLoading(names[key]);
          setTotalLoading(tempTotal++);
        }
      }
    }

    setIsLoading(subscriptions || mssl || monitoring || firewall || backups);
  }, [props]);

  useEffect(() => {
    getLoadingMessage();
  }, [props, totalLoading, firstLoading, isLoading]);

  return (
    <Alert
      id="loading-messages"
      title={intl.formatMessage(
        { id: loadingMessage.id },
        { ...loadingMessage.values },
      )}
      header={isLoading ? 'info' : 'success'}
      emphasis={isLoading ? 'info' : 'success'}
    />
  );
};

LoadingMessage.propTypes = {
  intl: PropTypes.shape(IntlShape),
  subscriptions: PropTypes.bool.isRequired,
  mssl: PropTypes.bool.isRequired,
  monitoring: PropTypes.bool.isRequired,
  backups: PropTypes.bool.isRequired,
  firewall: PropTypes.bool.isRequired,
};

export default LoadingMessage;
