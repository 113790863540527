import { data } from '@ux/data';
import axios from 'axios';
import { differenceInDays, fromUnixTime } from 'date-fns';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { pfidHasLegacyWS, pfidHasSecuritySuites } from '@/helpers/ApiHelper';
import { logger } from '@/common/initAPM';

export const useQualtricsMetrics = async () => {
  const intl = useIntl();
  const router = useRouter();

  if (!['/', '/tickets'].includes(router.pathname)) {
    return;
  }

  const qualtrics = {
    productName: '',
    daysSinceJoined: '',
    daysSincePurchase: '',
    productNameLocale: '',
    Experience: '',
    WSPlan: 'No WS',
    plid: data?.privateLabelId,
  };

  try {
    const subscriptionsQuery = axios.get('/api/shopper/subscriptions');
    const subscriptionsSearchQuery = axios.get(
      '/api/shields/subscriptions/search',
    );
    const msslQuery = axios.get('/api/mssl/tickets', {
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 404;
      },
    });
    const [subscriptions, search, mssl] = await Promise.all([
      subscriptionsQuery,
      subscriptionsSearchQuery,
      msslQuery,
    ]);

    // Filter subscriptions based on Date created to get the latest purchased
    // eslint-disable-next-line max-len
    const sortedSubscriptions = subscriptions?.data.subscriptions
      .filter(
        (item) =>
          item.product.label.includes('Managed SSL') ||
          item.product.productGroupKey === 'websiteSecurity',
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const latestSubscription =
      sortedSubscriptions.length && sortedSubscriptions[0];

    // Get the latest provisioned date
    const sortedSearch = search?.data.subscriptions.sort(
      (a, b) => new Date(b.enteredDate) - new Date(a.enteredDate),
    );
    const latestSearch = sortedSearch && sortedSearch[0];

    // Get the latest MSSL ticket
    const sortedMssl = mssl?.data?.list.sort(
      (a, b) => b.date_created - a.date_created,
    );
    const latestMsslTicket = sortedMssl.length && sortedMssl[0];

    if (latestSubscription) {
      qualtrics.productName = latestSubscription.product.label;
      qualtrics.daysSincePurchase = differenceInDays(
        new Date(),
        new Date(latestSubscription.createdAt),
      );

      if (
        latestSubscription.product.productGroupKey === 'websiteSecurity' &&
        latestSearch
      ) {
        qualtrics.daysSinceJoined = differenceInDays(
          new Date(),
          new Date(fromUnixTime(latestSearch.enteredDate / 1000)),
        );
        qualtrics.productNameLocale = intl.formatMessage({
          id: 'common__website_security',
        });
      } else {
        qualtrics.daysSinceJoined = differenceInDays(
          new Date(),
          new Date(fromUnixTime(latestMsslTicket.date_created)),
        );
        qualtrics.productNameLocale = intl.formatMessage({
          id: 'common__website_managedssl',
        });
      }
    }

    qualtrics.Experience =
      router.pathname === '/tickets' ? 'waiting-ticket' : 'new-overview';

    const hasLegacyWSProducts = sortedSubscriptions.some((subscription) => {
      if (subscription?.product?.pfid) {
        return pfidHasLegacyWS(subscription.product.pfid);
      }

      return false;
    });
    const hasSecuritySuitesProducts = sortedSubscriptions.some(
      (subscription) => {
        if (subscription?.product?.pfid) {
          return pfidHasSecuritySuites(subscription.product.pfid);
        }

        return false;
      },
    );

    if (hasLegacyWSProducts && hasSecuritySuitesProducts) {
      qualtrics.WSPlan = 'Legacy & Suites';
    } else if (hasSecuritySuitesProducts) {
      qualtrics.WSPlan = 'Suites';
    } else if (hasLegacyWSProducts) {
      qualtrics.WSPlan = 'Legacy';
    }

    window.qualtrics = qualtrics;
  } catch (error) {
    logger.error('Failed to reterive Qualtrics metrics', error);
  }
};
