import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { data } from '@ux/data';
import Button from '@ux/button';
import Modal from '@ux/modal';
import Text from '@ux/text';

import ErrorSVG from '@/client/assets/images/ill_error.svg';

const MsslErrorModal = ({ intl }) => {
  const router = useRouter();
  let phoneSupport = '';

  const getCloseModalRoute = () => {
    const modalOrigin = 'origin' in router.query ? router.query.origin : '';

    if (modalOrigin !== '') {
      router.push('/ticket?id=' + modalOrigin);
    } else {
      router.push('/');
    }
  };

  if (data && data.supportContacts) {
    phoneSupport = data.supportContacts.technicalSupportPhone.replace(
      / /g,
      '-',
    );
  }

  return (
    <Modal
      id="mssl-error-modal"
      title={
        <Text.h2
          text={intl.formatMessage({ id: 'provision_api_error_title' })}
          as="heading"
          size={1}
        />
      }
      onClose={() => {
        getCloseModalRoute();
      }}
    >
      <div className="provisionError error-modal-container text-center">
        <div className="row">
          <ErrorSVG
            width={200}
            height={200}
            title="provision error"
            className="sec-modal-header-image"
          />
          <p className={'text-uppercase mt-5'}>
            <FormattedMessage
              id="mssl_rekey_error"
              values={{ phone_number: phoneSupport }}
            />
          </p>
          <Button
            design="secondary"
            onClick={() => getCloseModalRoute()}
            text={intl.formatMessage({ id: 'close' })}
          />
        </div>
      </div>
    </Modal>
  );
};

MsslErrorModal.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(MsslErrorModal);
