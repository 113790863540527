import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Modal from '@ux/modal';
import Button from '@ux/button';
import Text from '@ux/text';
import Radio from '@ux/radio';
import { useIntl } from 'react-intl';

import Track, { events } from './track';

const SelectSubscriptionModal = ({
  subscriptions,
  domainData,
  titleId,
  productIcon,
  onClose,
  event,
}) => {
  const router = useRouter();
  const [subscriptionSelected, setSubscriptionSelected] = useState('');
  const intl = useIntl();
  const uniqueSubs = [];

  subscriptions.map((sub) => {
    if (
      !uniqueSubs.find(
        (uniqueSub) => uniqueSub.product.pfid === sub.product.pfid,
      )
    ) {
      uniqueSubs.push(sub);
    }
  });

  const handleRadioClick = (event) => {
    setSubscriptionSelected(event.target.value);
  };

  const handleSubmit = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    router.push(`/?setup=${subscriptionSelected}&domain=${domainData.domain}`);
  };

  const setupEvent = event ? event : events.INACTIVEPLANS_SETUP_CLICK;

  return (
    <Modal
      id="subscription-modal"
      title={
        <Text.h2
          text={intl.formatMessage({ id: titleId })}
          as="heading"
          size={1}
        />
      }
      onClose={onClose}
      className="site-settings-modal"
    >
      <div className="plan-settings-modal-container">
        {uniqueSubs.map((prod, index) => (
          <div
            className="card ux-card mssl-sub-card mb-3"
            key={`subscription-${index}`}
          >
            <div className="card-block d-flex flex-column flex-md-row justify-content-start align-items-center text-center text-sm-left">
              <Radio
                label={
                  <div className="d-flex justify-content-start align-items-center">
                    <span className="text-primary">{productIcon}</span>
                    <p className="h6 mb-0 ml-sm-2 mt-3 mt-md-0">
                      {prod.product.label}
                    </p>
                  </div>
                }
                value={prod.subscriptionId}
                name={`${prod.subscriptionId}`}
                id={`${prod.subscriptionId}`}
                key={prod.subscriptionId}
                checked={subscriptionSelected === prod.subscriptionId}
                onClick={handleRadioClick}
              />
            </div>
          </div>
        ))}
        <div
          className="d-flex justify-content-end"
          data-testid="sec-dash-mssl-select-set-up-button"
        >
          <Track id={setupEvent}>
            <Button
              design="primary"
              onClick={handleSubmit}
              disabled={!subscriptionSelected}
              text={intl.formatMessage({ id: 'setup_btn_text' })}
            />
          </Track>
        </div>
      </div>
    </Modal>
  );
};

SelectSubscriptionModal.propTypes = {
  subscriptions: PropTypes.array.isRequired,
  domainData: PropTypes.object.isRequired,
  titleId: PropTypes.string.isRequired,
  productIcon: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  event: PropTypes.string,
};

export default SelectSubscriptionModal;
