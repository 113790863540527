import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlShape } from 'react-intl';
import formatDistance from 'date-fns/formatDistance';

const dateString = (date, intl) => {
  if (!date || !(date instanceof Date)) {
    return '';
  }

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  const lastScan = intl.formatMessage({
    id: 'sitecheck_time_ago__last_scan',
    date: formatDistance(new Date(date), new Date(), { addSuffix: true }),
  });

  return lastScan;
};

export const SitecheckTimeAgo = ({ date, intl }) => {
  const [ago, setAgo] = useState('');

  useEffect(() => {
    setAgo(dateString(date, intl));
    const interval = setInterval(() => {
      setAgo(dateString(date, intl));
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return ago;
};

SitecheckTimeAgo.propTypes = {
  date: PropTypes.instanceOf(Date),
  intl: PropTypes.shape(IntlShape),
};

export default SitecheckTimeAgo;
