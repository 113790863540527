import SubscriptionsList from '../services/subscriptions/subscriptions-list';
import { isApiReseller } from '../../helpers/ApiHelper';

const useInactivePlans = (props) => {
  const {
    subscriptions: { data: subscriptions, loading, error },
    msslTickets: { data: mssl },
  } = props;

  if (loading || error) {
    return {
      websiteSecurity: {},
      backups: {},
      ssl: {},
    };
  }

  const inactiveWSSPlans = subscriptions
    .withWebSecurity()
    .inactive()
    .groupByProductID();

  const inactiveBackupsPlans = subscriptions
    .withBackups()
    .inactive()
    .groupByProductID();

  let inactiveSSL = new SubscriptionsList(
    ...subscriptions.withInactiveMSSL(mssl || []),
  );

  if (!isApiReseller()) {
    inactiveSSL = new SubscriptionsList(
      ...inactiveSSL,
      ...subscriptions.withSSL().inactive(),
    );
  }

  inactiveSSL = inactiveSSL.groupByProductID();

  return {
    websiteSecurity: inactiveWSSPlans,
    backups: inactiveBackupsPlans,
    ssl: inactiveSSL,
  };
};

export { useInactivePlans };
