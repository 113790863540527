import { isEmpty } from '../../common/helpers';
import { Endpoints, ActionList } from '../api-data';
import { useData } from './util.js';

const useMSSLTickets = (initialMsslTickets = []) => {
  const initialMsslTicketsValue = !isEmpty(initialMsslTickets)
    ? { list: initialMsslTickets }
    : [];
  return useData({
    endpoint: Endpoints.MSSL,
    action: ActionList,
    initialValue: initialMsslTicketsValue,
    outputFilter: (output) => {
      if (!output) {
        return [];
      }

      return output.list || [];
    },
  });
};

export { useMSSLTickets };
