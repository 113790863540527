(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dns"));
	else if(typeof define === 'function' && define.amd)
		define(["dns"], factory);
	else if(typeof exports === 'object')
		exports["_N_E"] = factory(require("dns"));
	else
		root["_N_E"] = factory(root["dns"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__75119__) {
return 