import PropTypes from 'prop-types';
import Modal from '@ux/modal';
import Text from '@ux/text';
import { injectIntl, IntlShape } from 'react-intl';

import SubscriptionService from '../services/subscriptions';
import { trackEvent, events } from '../../helpers/analytics';
import PlanSettingsCard from './plan-settings-card';

const PlanSettingsModal = ({
  domain,
  subscriptions,
  intl,
  onClose,
  onRemovePlan,
}) => {
  const remove = (plan) => async () => {
    const result = await SubscriptionService.cancelSubscription(plan);
    trackEvent(events.PLANSETTINGS_MODAL_REMOVE, 'click');
    if (result && !result.error) {
      onRemovePlan(false);
    }

    return result;
  };

  const close = () => {
    trackEvent(events.PLANSETTINGS_MODAL_CLOSE, 'click');
    onClose(false);
  };

  const cancelableSubscriptions = subscriptions.filter((s) => {
    return (
      typeof s !== 'undefined' &&
      s !== null &&
      (!Object.prototype.hasOwnProperty.call(s, 'cancelable') ||
        s.cancelable === true)
    );
  });

  return (
    <Modal
      id="plan-settings-modal"
      title={
        <Text.h2
          text={intl.formatMessage({ id: 'plansettingsmodal__title' })}
          as="heading"
          size={0}
        />
      }
      onClose={close}
      className="site-settings-modal"
    >
      <div className="plan-settings-modal-container">
        <h3 className="h6 text-muted font-weight-bold mb-4">{domain}</h3>
        <h5 className="h5 font-weight-bold mb-4">
          {intl.formatMessage({ id: 'plansettingsmodal__subheading' })}
        </h5>
        {cancelableSubscriptions.length === 0 && (
          <p>
            <em>
              {intl.formatMessage({
                id: 'plansettingsmodal__no_plans_to_manage',
              })}
            </em>
          </p>
        )}
        {cancelableSubscriptions.map((p) => {
          return (
            <PlanSettingsCard
              intl={intl}
              key={p.subscriptionId}
              plan={p}
              onRemovePlan={remove(p)}
            />
          );
        })}
      </div>
    </Modal>
  );
};

PlanSettingsModal.propTypes = {
  intl: PropTypes.shape(IntlShape),
  onClose: PropTypes.func,
  onRemovePlan: PropTypes.func,
  domain: PropTypes.string,
  subscriptions: PropTypes.array.isRequired,
};

export default injectIntl(PlanSettingsModal);
