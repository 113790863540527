import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@ux/button';
import AlertIcon from '@ux/icon/alert';
import CheckmarkIcon from '@ux/icon/checkmark';
import ClockIcon from '@ux/icon/clock';
import HelpIcon from '@ux/icon/help';
import CartIcon from '@ux/icon/cart';

import { MsslMessageNotification } from './mssl-status';
import Track, { events } from '../track';
import StatusSpinner from './status-spinner';
import intents from '../../common/intents-config';
import { STATUS_ICON_WIDTH, STATUS_ICON_HEIGHT } from '../../common/constants';

const rootClasses = [
  'site-card-product',
  'd-flex',
  'flex-row',
  'flex-lg-column',
  'justify-content-start',
  'text-lg-center',
  'text-left',
  'align-items-center',
  'ml-lg-3',
  'pl-lg-2',
  'pr-lg-2',
  'pl-xl-3',
  'pr-xl-3',
  'mt-0',
  'py-md-2',
  'py-4',
];

const iconClasses = [
  'ms4',
  'mt-sm-2',
  'mb-sm-2',
  'mt-md-4',
  'mb-md-4',
  'mt-1',
  'mb-1',
  'mr-2',
  'mr-lg-0',
  'site-card-product-wrapper',
];

const statusClasses = [
  'h6',
  'font-weight-normal',
  'mb-0',
  'mb-lg-4',
  'ml-2',
  'ml-lg-0',
];

const buttonClasses = ['ml-auto', 'p-0', 'ml-lg-0'];

const statuses = {
  ERROR: 'error',
  LOADING: 'loading',
  MESSAGES: 'messages',
  OK: 'ok',
  PENDING: 'pending',
  PURCHASE: 'purchase',
  UNKNOWN: 'unknown',
  WARNING: 'warning',
};

/**
 * @param {string} product a product name slug
 * @returns {React.ReactNode} A react-renderable element with the nice-looking product title
 */
const useTitle = (product) => {
  let title;
  switch (product) {
    case 'monitoring':
      title = <FormattedMessage id="common__monitoring" />;
      break;
    case 'firewall':
      title = <FormattedMessage id="common__firewall" />;
      break;
    case 'mssl':
      title = <FormattedMessage id="common__https" />;
      break;
    case 'backups':
      title = <FormattedMessage id="common__backups" />;
      break;
    default:
      title = 'Unknown Product';
  }

  return title;
};

/**
 * @param {string} status one of the statuses constants
 * @param {{messageCount: number}} extra arbitary object with extra parameters
 * @returns {React.ReactNode} A react-renderable element with the nice-looking product title
 */
const useIcon = (status, extra) => {
  let icon;

  const defaultIconProps = {
    width: STATUS_ICON_WIDTH,
    height: STATUS_ICON_HEIGHT,
  };

  switch (status) {
    case statuses.ERROR:
      icon = (
        <AlertIcon
          color={intents.ux.feedbackHighContrastCritical.feedbackColor}
          {...defaultIconProps}
        />
      );
      break;
    case statuses.OK:
      icon = (
        <CheckmarkIcon
          color={intents.ux.feedbackHighContrastSuccess.feedbackColor}
          {...defaultIconProps}
        />
      );
      break;
    case statuses.PENDING:
      icon = (
        <ClockIcon
          color={intents.ux.controlVoid.foregroundColor}
          {...defaultIconProps}
        />
      );
      break;
    case statuses.MESSAGES:
      icon = (
        <MsslMessageNotification
          messageCount={extra.messageCount || 0}
          {...defaultIconProps}
        />
      );
      break;
    case statuses.WARNING:
      icon = (
        <AlertIcon
          color={intents.ux.feedbackHighContrastWarning.feedbackColor}
          {...defaultIconProps}
        />
      );
      break;
    case statuses.PURCHASE:
      icon = <CartIcon color="black" {...defaultIconProps} />;
      break;
    case statuses.UPGRADE:
      icon = <AlertIcon color="black" {...defaultIconProps} />;
      break;
    case statuses.UNKNOWN:
    default:
      icon = (
        <HelpIcon
          color={intents.ux.controlVoid.foregroundColor}
          {...defaultIconProps}
        />
      );
  }

  return icon;
};

const ProductPivot = (props) => {
  const {
    extra,
    product,
    status,
    statusTextID,
    buttonTextID,
    buttonDisabled = false,
    onClick = () => {},
    hideButton,
    rating,
    customEvent,
    version = '',
  } = props;

  const title = useTitle(product);
  const icon = useIcon(status, extra);
  const intl = useIntl();
  const event = customEvent
    ? customEvent
    : events.MYSITES_SITECARD_PRODUCT_CLICK;

  const localButtonClasses = [...buttonClasses];
  if (hideButton) {
    localButtonClasses.push('invisible');
  }

  return (
    <div
      className={rootClasses.join(' ')}
      data-testid={`site-card-pivot-${product}`}
      data-version={version}
    >
      <h6 className="h5 font-weight-bold mb-0">{title}</h6>
      <div className={iconClasses.join(' ')}>
        <StatusSpinner loading={status === statuses.LOADING}>
          <span className="text-center">{icon}</span>
        </StatusSpinner>
      </div>
      <p
        className={statusClasses.join(' ')}
        data-testid={'product-pivot-status-text'}
      >
        <FormattedMessage id={statusTextID} />
      </p>
      {statuses.UNKNOWN !== status && (
        <Track
          id={event}
          data={{
            product: product,
            text: buttonTextID,
            rating: rating,
          }}
        >
          <Button
            onClick={onClick}
            design="inline"
            className={localButtonClasses.join(' ')}
            data-testid={`sec-dash-pivot-button-${product}`}
            text={intl.formatMessage({ id: buttonTextID })}
            icon={<span className="d-lg-none btn-arrow" />}
            disabled={buttonDisabled}
          />
        </Track>
      )}
    </div>
  );
};
ProductPivot.propTypes = {
  buttonTextID: PropTypes.node,
  extra: PropTypes.object,
  hideButton: PropTypes.bool,
  onClick: PropTypes.func,
  product: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(statuses)),
  statusTextID: PropTypes.node,
  rating: PropTypes.string,
  customEvent: PropTypes.string,
};

ProductPivot.defaultProps = {
  statusTextID: 'unknown',
  buttonTextID: 'common__details',
  extra: {},
};

export { statuses, ProductPivot };

export default ProductPivot;
