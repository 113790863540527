// this can become a folder of ts types one day

import PropTypes from 'prop-types';

const { bool, object, string, shape, number, array } = PropTypes;

const date = string;
const uuid = string;

export const subscriptions = shape({
  sslOnly: bool,
  tickets: array,
  data: array,
});

export const subscriptionType = shape({
  addons: array,
  billing: object,
  createdAt: date,
  expiresAt: date,
  externalId: uuid,
  label: string,
  nesSubscriptionId: string,
  paymentProfileId: number,
  priceLocked: bool,
  product: object,
  // ... theres more here
  upgradable: bool,
});

export const ticketType = shape({
  // ?
});
